import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dictionary } from '@reduxjs/toolkit';
import { useAppSelector } from 'hooks/reduxHooks';
import { AppRoute } from 'enums/AppRoute';
import useCurrentBorrower from 'hooks/useCurrentBorrower';
import { IProduct } from 'api/digifi/ProductsApi';
import CreateNewApplicationProductSelector from './CreateNewApplicationProductSelector';

interface ICreateNewApplicationProductSelectorConnectorProps {
  onClose: () => void;
}

const CreateNewApplicationProductSelectorConnector: FC<ICreateNewApplicationProductSelectorConnectorProps> = ({
  onClose,
}) => {
  const navigate = useNavigate();
  const borrower = useCurrentBorrower();
  const products = useAppSelector((state) => state.products.entities);

  const filteredProducts = useMemo(() => {
    return Object.entries(products).reduce((result, [id, product]) => {
      if (product && borrower && product.borrowerTypes.includes(borrower.type)) {
        result[id] = product;
      }

      return result;
    }, {} as Dictionary<IProduct>);
  }, [products, borrower]); 

  const handleSubmit = useCallback((productId: string, coborrowersCount: number) => {
    const params = new URLSearchParams();

    params.append('productId', productId);
    params.append('coborrowersCount', coborrowersCount.toString());

    navigate(`${AppRoute.Apply}?${params}`);
  }, []);

  return (
    <CreateNewApplicationProductSelector
      products={filteredProducts}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default CreateNewApplicationProductSelectorConnector;
