import { DateTime } from 'luxon';
import { DateTimeFormat } from 'product_modules/utils/dateFormat';
import TranslationService from 'product_modules/services/TranslationService';

const formatDate = (date: string, format: DateTimeFormat = DateTimeFormat.Long) => {
  const locale = TranslationService.getCurrentLocale();
  const dateObject = new Date(Date.parse(date));

  if (dateObject.toISOString() === date) {
    return DateTime.fromISO(date).toFormat(format, { locale });
  }

  return DateTime.fromFormat(date, DateTimeFormat.Short).toFormat(format, { locale });
};

export default formatDate;
