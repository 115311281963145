import { FC } from 'react';
import clsx from 'clsx';
import LoaderWithState, { LoaderWithStateProps } from 'product_modules/components/LoaderWithState';
import styles from 'components/digifi-wrappers/Spinner/Spinner.module.scss';

const LoaderWithStateWrapper: FC<LoaderWithStateProps> = (props) => {
  return (
    <LoaderWithState {...props} spinnerClassName={clsx(styles.spinner, props.spinnerClassName)} />
  );
};

export default LoaderWithStateWrapper;
