import clsx from 'clsx';
import { rgba } from 'polished';
import React, { FC } from 'react';
import { lowerFirst } from 'lodash';
import styles from './TableInputHeader.module.scss';
import styled from 'types/AppTheme';
import Label from 'product_modules/components/Label';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import OverflowedText from 'product_modules/components/OverflowedText';
import ContainerWithPatternStyled from 'components/BrandingHelpers/ContainerWithPatternStyled';
import ButtonWithImage from 'product_modules/components/ButtonWithImage';
import ButtonWithImageWrapper from 'components/digifi-wrappers/ButtonWithImage/ButtonWithImage';
import TranslationService from 'product_modules/services/TranslationService';
import { ButtonWithImageKind } from 'product_modules/components/ButtonWithImage/ButtonWithImage';

interface TableInputHeaderProps {
  label: string;
  onClick: () => void;
  isExpanded?: boolean;
  viewMode?: boolean;
  isStub?: boolean;
  errorMessage?: string;
  inputTooltip?: React.ReactNode;
  topRightElement?: React.ReactNode;
  classNames?: {
    tableHeaderViewMode?: string;
    headerContainerExpanded?: string;
    tableContainerExpanded?: string;
    labelExpanded?: string;
    collapseButton?: string;
    labelTooltip?: string;
  };
  required?: boolean;
  titleHint?: string;
}

const DESCRIPTION_ICON_CLASS_NAME = 'description-icon';

const LabelStyled = styled(Label)`
  .${DESCRIPTION_ICON_CLASS_NAME}:hover:after {
    color: ${({ theme }) => theme.colors.accentColor};
    border-color: ${({ theme }) => rgba(theme.colors.accentColor, 0.4)};
  }
`;

const getButtonWithImageTitle = (isExpanded?: boolean) =>
  isExpanded
    ? TranslationService.translate('otherComponents.collapse')
    : TranslationService.translate('otherComponents.expand');

const getButtonWithImageKind = (isExpanded?: boolean) =>
  lowerFirst(
    isExpanded
      ? TranslationService.translate('otherComponents.collapse')
      : TranslationService.translate('otherComponents.expand'),
  ) as ButtonWithImageKind;

const TableInputHeader: FC<TableInputHeaderProps> = ({
  label,
  onClick,
  isExpanded,
  viewMode,
  isStub,
  errorMessage,
  children,
  inputTooltip,
  topRightElement,
  classNames,
  required,
  titleHint,
}) => {
  const headerContainerClassName = clsx(
    styles.headerContainer,
    viewMode && styles.headerContainerViewMode,
    !viewMode && !isStub && styles.headerContainerEditMode,
    isExpanded && styles.headerContainerExpanded,
    isExpanded && classNames?.headerContainerExpanded,
  );

  const labelClassName = clsx(
    styles.label,
    viewMode && styles.labelViewMode,
    isExpanded && styles.labelExpanded,
    isExpanded && classNames?.labelExpanded,
  );

  const tableContainerClassName = clsx(
    styles.tableContainer,
    isExpanded && styles.tableContainerExpanded,
  );

  const renderTableLabel = () => {
    if (viewMode && !isExpanded) {
      return (
        <OverflowedText className={styles.labelViewMode}>{label}</OverflowedText>
      );
    }

    return (
      <LabelStyled
        className={labelClassName}
        required={required}
        tooltip={titleHint}
        questionIconClassName={clsx(
          isExpanded && styles.expandedDescription,
          DESCRIPTION_ICON_CLASS_NAME,
          classNames?.labelTooltip,
        )}
      >
        {label}
      </LabelStyled>
    );
  };

  const renderHeaderContent = (ButtonWithImageComponent: typeof ButtonWithImage | typeof ButtonWithImageWrapper) => {
    return (
      <>
        {renderTableLabel()}
        <div className={styles.headerRightSide}>
          {!isExpanded && topRightElement}
          <ButtonWithImageComponent
            title={getButtonWithImageTitle(isExpanded)}
            kind={getButtonWithImageKind(isExpanded)}
            onClick={onClick}
            className={clsx(
              styles.buttonWithImage,
              isExpanded && styles.collapseButton,
              isExpanded && classNames?.collapseButton,
            )}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {isExpanded && (
        <ContainerWithPatternStyled className={clsx(headerContainerClassName, styles.extendedHeader)}>
          {renderHeaderContent(ButtonWithImage)}
        </ContainerWithPatternStyled>
      )}
      {!isExpanded && (
        <div className={headerContainerClassName}>
          {renderHeaderContent(ButtonWithImageWrapper)}
        </div>
      )}
      <WrapperWithTooltip tooltip={inputTooltip}>
        <div className={clsx(isExpanded && styles.expandedWrapper, isExpanded && classNames?.tableContainerExpanded)}>
          <div className={tableContainerClassName}>
            {children}
          </div>
          {errorMessage && (
            <div className={clsx(styles.errorMessage, isExpanded && styles.errorMessageExpanded)}>
              {errorMessage}
            </div>
          )}
        </div>
      </WrapperWithTooltip>
    </>
  );
};

export default TableInputHeader;
