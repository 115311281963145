import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TasksElement.module.scss';

const NoTasks: FC = () => {
  const { t } = useTranslation();
  
  return (
    <div className={styles.noTasksContainer}>
      <p className={styles.taskTitle}>{t('pageBuilderElements.noOutstandingTasks')}</p>
      <p className={styles.description}>{t('pageBuilderElements.noOutstandingTasksPrompt')}</p>
    </div>
  );
};

export default NoTasks;
