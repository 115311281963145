import { useTranslation } from 'react-i18next';
import BaseIntegrationElement from 'components/PortalPageElements/BaseIntegrationElement';
import { DocuSignLogo } from 'static/images';

const NoDocumentsToSign = () => {
  const { t } = useTranslation();
  
  return (
    <BaseIntegrationElement
      logo={<DocuSignLogo />}
      title={t('pageBuilderElements.noDocumentsToSign')}
      description={t('pageBuilderElements.noDocumentsToSignPrompt')}
    />
  );
};

export default NoDocumentsToSign;
