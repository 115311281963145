import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateAccountForm from 'components/Auth/forms/CreateAccountForm';
import { acceptInvite, getCurrentAccount } from 'handlers/authSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { ICreateAccountFormParams } from 'types';
import { createNotification } from 'handlers/notificationsSlice';
import styles from './AcceptInvite.module.scss';

interface IAcceptInviteProps {
  token: string;
}

const AcceptInvite: FC<IAcceptInviteProps> = ({ token }) => {
  const { t } = useTranslation();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { inviteData } = useAppSelector((state) => state.auth);

  const handleSubmit = async (values: ICreateAccountFormParams) => {
    try {
      setIsLoading(true);
      await dispatchWithUnwrap(acceptInvite({ params: values, token }));
      await dispatchWithUnwrap(getCurrentAccount());
    } catch (error) {
      createNotification({ notification: t('toastMessages.accountNotFound'), type: 'error', dispatch });
    } finally {
      setIsLoading(false);
    }
  };

  const initialData = {
    email: inviteData?.email,
    phoneNumber: inviteData?.phone,
  };

  return (
    <CreateAccountForm
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      initialData={initialData}
      submitButtonClassName={styles.submitButton}
      useLegalConsents={false}
    />
  );
};

export default AcceptInvite;
