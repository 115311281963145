import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import ResetPasswordForm from 'components/Auth/forms/ResetPasswordForm';
import { IResetPasswordContextType } from 'components/Auth/forms/ResetPasswordForm/ResetPasswordForm';
import { resetPassword } from 'handlers/authSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { AppRoute } from 'enums/AppRoute';
import { createNotification } from 'handlers/notificationsSlice';
import getMessage, { MessageType } from 'constants/Messages';
import { useAppDispatch } from 'hooks/reduxHooks';
import FormTitle from 'components/PageLayout/FormTitle';
import AuthFormContainer from 'components/Auth/AuthFormContainer';

const ResetPassword: FC = () => {
  const { t } = useTranslation();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (values: IResetPasswordContextType) => {
    try {
      setIsLoading(true);
      await dispatchWithUnwrap(resetPassword(values.email));
      setIsLoading(false);
      createNotification({
        notification: getMessage(MessageType.PasswordResetEmailSent, { email: values.email }),
        type: 'success',
        dispatch,
      });
      navigate(AppRoute.SignIn);
    } catch (error) {
      setIsLoading(false);
      createNotification({ type: 'error', notification: error.message, dispatch });
    }
  };

  return (
    <UnauthorizedLayout
      footerLinkText={t('common.backToSignIn')}
      footerLink={AppRoute.SignIn}
    >
      <AuthFormContainer>
        <FormTitle noMargin title={t('common.resetPassword')} subTitle={t('resetPassword.resetPasswordPrompt')} />
        <ResetPasswordForm handleSubmit={handleSubmit} isLoading={isLoading} />
      </AuthFormContainer>
    </UnauthorizedLayout>
  );
};

export default ResetPassword;
