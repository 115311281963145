import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BaseApplicationHeaderWrapper from 'components/BaseApplicationHeaderWrapper';
import Button from 'components/digifi-wrappers/Button';
import DiscardApplicationPopUp from 'components/ApplicationForm/DiscardApplicationPopUp';
import OverflowedText from 'components/digifi-wrappers/OverflowedText';
import usePopups from 'hooks/usePopups';
import styles from './CreateNewApplicationHeader.module.scss';

interface ICreateNewApplicationHeaderProps {
  productName: string;
  showDiscardButton?: boolean;
}

const CreateNewApplicationHeader: FC<ICreateNewApplicationHeaderProps> = ({ productName, showDiscardButton }) => {
  const { t } = useTranslation();

  const { openPopup, renderPopups } = usePopups({
    discardApplication: ({ onClose }) => <DiscardApplicationPopUp onClose={onClose} />,
  });

  return (
    <>
      <BaseApplicationHeaderWrapper>
        <OverflowedText className={styles.productName} useTooltip>
          {productName}
        </OverflowedText>
        {showDiscardButton && (
          <Button
            onClick={() => openPopup('discardApplication')}
            size='small'
            kind='primary-relaxed'
            className={styles.discardButton}
          >
            <p className={styles.desktopTitle}>{t('startNewApp.discardApplication')}</p>
            <p className={styles.mobileTitle}>{t('startNewApp.discard')}</p>
          </Button>
        )}
      </BaseApplicationHeaderWrapper>
      {renderPopups()}
    </>
  );
};

export default CreateNewApplicationHeader;
