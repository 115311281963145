import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentImage } from 'product_modules/static/images';
import ButtonWithImage from 'components/digifi-wrappers/ButtonWithImage';
import styles from './FilePreviewLoader.module.scss';

export enum LoaderType {
  Standard = 'standard',
}

interface IFilePreviewLoaderProps {
  onDownload: () => void;
  type?: LoaderType;
  message?: string;
  downloadAvailable?: boolean;
}

const DEFAULT_MESSAGE = 'Preparing File For Viewing';

const FilePreviewLoader: FC<IFilePreviewLoaderProps> = ({
  type = LoaderType.Standard,
  message = DEFAULT_MESSAGE,
  downloadAvailable,
  onDownload,
}) => {
  const { t } = useTranslation();

  const getLoaderIcon = () => {
    switch (type) {
      case LoaderType.Standard: {
        return (
          <DocumentImage />
        );
      }
    }
  };

  return (
    <>
      {getLoaderIcon()}
      <p>{message}</p>
      {downloadAvailable && (
        <ButtonWithImage
          className={styles.downloadLinkBtn}
          title={t('tooltips.downloadDocument')}
          kind="download"
          onClick={onDownload}
        />
      )}
    </>
  );
};

export default FilePreviewLoader;
