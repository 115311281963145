import { FC } from 'react';
import { keyBy } from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import formatValueByVariable from 'product_modules/utils/formatValueByVariable';
import Checkbox from 'components/digifi-wrappers/Checkbox/Checkbox';
import TableRow from 'components/digifi-wrappers/TableRow';
import TableBodyCell from 'components/digifi-wrappers/TableBodyCell';
import OverflowedText from 'components/digifi-wrappers/OverflowedText';
import { HandleKeyDown } from 'product_modules/utils/TriggerEventOnEnterKeyDown';
import KeyEventEnum from 'product_modules/common/KeyEventEnum';
import { TableRowValue } from 'product_modules/api/Types';
import useVariableBySystemName from 'product_modules/loaders/Variables/hooks/useVariableBySystemName';
import { TableVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import styles from './OfferSelectionElement.module.scss';

export interface IOfferCardProps {
  index: number;
  checked: boolean;
  onCheckboxValueChange: (index: number) => void;
  offer: TableRowValue;
  displayedColumns: string[];
  variableName: string;
  className?: string;
}

const OfferCard: FC<IOfferCardProps> = ({
  index,
  checked,
  onCheckboxValueChange,
  offer,
  displayedColumns,
  variableName,
  className,
}) =>  {
  const { t } = useTranslation();
  const variable = useVariableBySystemName(variableName);
  const tableColumnsBySystemName = keyBy((variable?.visualAttributes as TableVisualAttributes)?.columns, 'systemName');

  const renderTableRow = (title: string, value: string, rowIndex: number) => (
    <TableRow hasTopBorder={!rowIndex} noBottomBorder={rowIndex === displayedColumns.length - 1} key={rowIndex} disableHover>
      <TableBodyCell width={59} className={clsx(styles.firstColumn, styles.bodyCellMobile)}>
        <OverflowedText>{title}</OverflowedText>
      </TableBodyCell>
      <TableBodyCell width={41} overflowed={false} className={styles.bodyCellMobile}>
        <OverflowedText>{value}</OverflowedText>
      </TableBodyCell>
    </TableRow>
  );

  const renderTable = () => {
    return displayedColumns
      .filter((column) => !!tableColumnsBySystemName[column])
      .map((column, rowIndex) => {
        return renderTableRow(
          tableColumnsBySystemName[column]?.name,
          tableColumnsBySystemName[column] ? formatValueByVariable(offer[column], {
            visualAttributes: tableColumnsBySystemName[column].visualAttributes,
            visualDataType: tableColumnsBySystemName[column].visualDataType,
          }) : '',
          rowIndex,
        );
    });
  };

  return (
    <div className={clsx(styles.offerCard, className)} key={index}>
      <div className={styles.titleRow}>
        <p className={styles.cardTitle}>{t('pageBuilderElements.offer', { index: index + 1 })}</p>
        <Checkbox
          checked={checked}
          onChange={() => onCheckboxValueChange(index)}
          onKeyDown={HandleKeyDown(KeyEventEnum.Enter, () => onCheckboxValueChange(index))}
          labelClassName={styles.checkbox}
          rounded
        />
      </div>
      {renderTable()}
    </div>
  );
};

export default OfferCard;
