import { ReactNode } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import OptionCardContainerStyled from './OptionCardContainerStyled';
import OptionIcon from './OptionIcon';
import Checkbox from 'components/common/Checkbox';
import Header from 'product_modules/components/Header';
import Tag from 'components/digifi-wrappers/Tag';
import { CloseIcon } from 'static/images';
import styles from './OptionCard.module.scss';

export interface IOptionCardProps {
  isSelected?: boolean;
  className?: string;
  cardClassName?: string;
  icon?: ReactNode;
  text?: string;
  useCheckbox?: boolean;
  required?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
}

const ApplicantTypeCard = ({
  isSelected,
  className,
  cardClassName,
  icon,
  text,
  useCheckbox = true,
  required,
  onClick,
  onRemove,
}: IOptionCardProps) => {
  const { t } = useTranslation();
  
  return (
    <OptionCardContainerStyled
      className={clsx(styles.card, isSelected && styles.cardSelected, cardClassName)}
      onClick={onClick}
      isSelected={isSelected}
    >
      <div className={clsx(styles.cardContent, className)}>
        {useCheckbox && <Checkbox isSelected={isSelected} className={clsx(styles.checkbox, isSelected && styles.checkboxSelected)} />}
        {required && <Tag medium className={styles.tag}>{t('questionPages.required')}</Tag>}
        {onRemove && <CloseIcon className={styles.closeIcon} onClick={() => onRemove?.()} />}
        <OptionIcon isSelected={isSelected} icon={icon} />
        <Header className={styles.text} type='h5'>{text}</Header>
      </div>
    </OptionCardContainerStyled>
  );
};

export default ApplicantTypeCard;
