import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import TextInput from 'components/digifi-wrappers/TextInput';
import TopRightButton from 'components/digifi-wrappers/TopRightInputButton';
import useTimer from 'product_modules/hooks/useTimer';
import TimerMessage from 'components/digifi-wrappers/TimerMessage';
import ButtonWithLoadingState from 'components/digifi-wrappers/ButtonWithLoadingState';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';
import { ButtonType } from 'product_modules/components/Button/Button';
import { removeNonDigits } from 'utils/stringUtils';
import styles from 'components/BorrowerProfile/LoginDetails/BasePopupSteps/VerifyCodeStep/VerifyCodeStep.module.scss';

interface IVerifyCodeStepProps {
  message: string;
  buttonText: string;
  buttonKind?: ButtonType;
  onResendCode: () => Promise<void>;
  onSubmit: (code: string) => Promise<void>;
  isLoading?: boolean;
}

const MAX_CODE_LENGTH = 6;

const VerifyCodeStep: FC<IVerifyCodeStepProps> = ({
  message,
  buttonText,
  buttonKind = 'primary',
  onResendCode,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const { isRunning, seconds, restart } = useTimer();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const resendInSeconds = isRunning ? seconds : 0;

  const isCodeInvalid = code.length < MAX_CODE_LENGTH;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const digitsOnly = removeNonDigits(event.target.value);
    setCode(digitsOnly);
  };

  const handleResendCode = () => {
    onResendCode();
    restart();
  };

  const topRightElement = (
    <TopRightButton disabled={resendInSeconds > 0} onClick={handleResendCode}>
      {t('common.resendPhoneCode')}
    </TopRightButton>
  );

  return (
    <div>
      <div className={styles.message}>{message}</div>
      <TextInput
        placeholder={t('loginDetails.sixDigits')}
        labelTitle={t('loginDetails.verificationCode')}
        value={code}
        required
        onChange={handleOnChange}
        maxLength={6}
        topRightElement={topRightElement}
        onKeyDown={TriggerEventOnEnterKeyDown(buttonRef, 'click')}
        disabled={isLoading}
        type='tel'
      />
      {isRunning && (
        <TimerMessage className={styles.timerMessage}>
          <Trans i18nKey='common.requestAnotherCode'>
            You can request another code in <strong>{{resendInSeconds}}</strong>s
          </Trans>
        </TimerMessage>
      )}
      <ButtonWithLoadingState
        ref={buttonRef}
        className={styles.button}
        disabled={isCodeInvalid}
        kind={buttonKind}
        size='form'
        onClick={() => onSubmit(code)}
      >
        {buttonText}
      </ButtonWithLoadingState>
    </div>
  );
};

export default VerifyCodeStep;
