import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PlaidLinkElement from 'components/PortalPageElements/PlaidLinkElement/PlaidLinkElement';
import { IPlaidLinkElementConfig } from 'api/digifi/portal-page-elements';
import {
  useCreateIncomeLinkTokenMutation,
  useCreateFinancialLinkTokenMutation,
  useCreateAccessTokenMutation,
  useGetPlaidStatusQuery,
} from 'slices';
import { PlaidProduct } from 'api/digifi/PlaidApi';
import { setIsPlaidConnected } from 'handlers/plaidSlice';

interface IPlaidLinkElementConnectorProps {
  config?: IPlaidLinkElementConfig;
  containerClassName?: string;
}

const PlaidLinkElementConnector = ({
  config,
  containerClassName,
}: IPlaidLinkElementConnectorProps) => {
  const dispatch = useDispatch();
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const isIncomeConfig = useMemo(() => config?.products.includes(PlaidProduct.Income), [config]);

  const [
    createIncomeLinkToken,
    { isLoading: isCreateIncomeLinkTokenLoading },
  ] = useCreateIncomeLinkTokenMutation();

  const [
    createFinancialLinkToken,
    { isLoading: isCreateFinancialLinkTokenLoading },
  ] = useCreateFinancialLinkTokenMutation();

  const [
    createAccessToken,
    { isLoading: isCreateAccessTokenLoading },
  ] = useCreateAccessTokenMutation();

  const {
    currentData: plaidStatus,
    isLoading: isPlaidLinkDataLoading,
  } = useGetPlaidStatusQuery();

  const handleSuccess = useCallback(async (publicToken) => {
    if (!isIncomeConfig || config?.additionalProducts?.length) {
      await createAccessToken({
        publicToken,
      }).unwrap();
    }

    dispatch(setIsPlaidConnected());
  }, []);

  const handleIncomeConnect = useCallback(async () => {
    const products = [
      ...(config?.products || []),
      ...(config?.additionalProducts || []),
    ];

    const { token } = await createIncomeLinkToken({
      linkCustomizationName: config?.linkCustomizationName,
      incomeSourceType: config?.incomeSource,
      daysRequested: config?.daysRequested,
      products,
    }).unwrap();

    setLinkToken(token);
  }, [config]);

  const handleFinancialConnect = useCallback(async () => {
    const { token } = await createFinancialLinkToken({
      products: config?.products || [],
      linkCustomizationName: config?.linkCustomizationName,
    }).unwrap();

    setLinkToken(token);
  }, [config]);

  const isLoading =
    isCreateIncomeLinkTokenLoading ||
    isCreateFinancialLinkTokenLoading ||
    isCreateAccessTokenLoading ||
    isPlaidLinkDataLoading;

  return (
    <div className={containerClassName}>
      <PlaidLinkElement
        token={linkToken}
        isLoading={isLoading}
        disabled={!plaidStatus?.isConnected || !config}
        onConnect={isIncomeConfig ? handleIncomeConnect : handleFinancialConnect}
        onSuccess={handleSuccess}
        onExit={() => setLinkToken(null)}
      />
    </div>
  );
};

export default PlaidLinkElementConnector;
