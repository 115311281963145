import { FC } from 'react';
import clsx from 'clsx';
import { MinusIcon, PlusIcon } from 'product_modules/static/images';
import { MAX_ZOOM, MIN_ZOOM } from 'components/common/ImageViewer/ImageViewer';
import styles from './Controls.module.scss';

interface IControlsProps {
  onZoom: (zoom: number) => void;
  onReset: () => void;
  zoom: number;
  className?: string;
}

const ZOOM_STEP = 0.25;

const Controls: FC<IControlsProps> = ({
  onZoom,
  onReset,
  zoom,
  className,
}) => {
  return (
    <div className={clsx(styles.tools, className)}>
      <div
        onClick={() => zoom > MIN_ZOOM && onZoom(zoom - ZOOM_STEP)}
        className={clsx(styles.zoomIcon, zoom === MIN_ZOOM && styles.disabled)}
      >
        <MinusIcon />
      </div>
      <div
        onClick={onReset}
        className={styles.controlButton}
      >
        {Math.round(zoom * 100)}%
      </div>
      <div
        onClick={() => zoom < MAX_ZOOM && onZoom(zoom + ZOOM_STEP)}
        className={clsx(styles.zoomIcon, zoom === MAX_ZOOM && styles.disabled)}
      >
        <PlusIcon />
      </div>
    </div>
  );
};

export default Controls;
