import { FC } from 'react';
import { Trans } from 'react-i18next';
import { useAppDispatch } from 'hooks/reduxHooks';
import { setSelectedDocument } from 'handlers/settingsSlice';
import ModalOverlay from 'components/common/ModalOverlay';
import formatDate from 'utils/formatDate';
import { DateTimeFormat } from 'product_modules/utils/dateFormat';
import styles from './LegalDocumentPopUp.module.scss';

interface ILegalDocumentPopUp {
  name: string;
  body: string;
  updatedAt: string;
}

const LegalDocumentPopUp: FC<ILegalDocumentPopUp> = ({ name, body, updatedAt }) => {
  const dispatch = useAppDispatch();

  const handlePopUpClose = () => {
    dispatch(setSelectedDocument(null));
  };

  return (
    <ModalOverlay title={name} onClose={handlePopUpClose} usePortal>
      <div className={styles.documentBodyContainer}>
        <div className={styles.documentBody}>{body}</div>
        <div className={styles.lastModifiedDate}>
          <Trans i18nKey='consent.dateLastModified'>
            Last Modified: {{ date: formatDate(updatedAt, DateTimeFormat.LongMonthDate) }}
          </Trans>
        </div>
      </div>
    </ModalOverlay>
  );
};

export default LegalDocumentPopUp;
