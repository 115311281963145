import { FC, FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IButtonPageElement, IESignatureElement,
  IOfferSelectionPageElement,
  PortalPageElementType,
} from 'api/digifi/portal-page-elements';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import PageNoAvailable from 'components/ApplicationPage/PageNoAvailable';
import useAsyncActionCallback from 'product_modules/hooks/useAsyncActionCallback';
import { findApplicationTasks } from 'handlers/tasksSlice';
import ApplicationPage from './ApplicationPage';
import { getAutomationWorkflowById } from 'handlers/automationWorkflowSlice';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import { TableValue, VariableValue } from 'product_modules/api/Types';
import { IPortalPageVariableConfiguration } from 'api/digifi/layout/VariableConfigurationsApi';
import { submitPortalPageInfo } from 'handlers/portal-page-info/Thunks';
import { setApplicationData } from 'handlers/applicationDataSlice';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import { createNotification } from 'handlers/notificationsSlice';
import { IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import isTrimmableString from 'utils/isTrimmableString';
import useCurrentApplication from 'hooks/useCurrentApplication';
import { useGeneratePortalPageImageUrlsMutation, useGetPortalPageElementsQuery } from 'slices';
import useImageUrls, { GenerateImageUrls } from 'components/PortalPageElements/hooks/useImageUrls';
import { setIsPlaidElementExists } from 'handlers/plaidSlice';

interface IApplicationPageConnectorProps {
  application: IApplicationViewModel;
  pageNotAvailableClassName?: string;
  className?: string;
}

const ApplicationPageConnector: FC<IApplicationPageConnectorProps> = ({
  application,
  pageNotAvailableClassName,
  className,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const currentApplication = useCurrentApplication();

  const { offersVariable, isSubmitInProgress } = useAppSelector((state) => state.applicationData);

  const [portalPageFormData, setPortalPageFormData] = useState({});
  const [offersVariableValue, setOffersVariableValue] = useState<TableValue | null>(null);

  const handlePortalPageFieldChange = useCallback((
    _variableConfiguration: IPortalPageVariableConfiguration,
    variable: Variable,
    value: VariableValue,
  ) => {
    setPortalPageFormData((previousPortalPageFormData) => ({
      ...previousPortalPageFormData,
      [variable.systemName]: value,
    }));
  }, []);

  const handlePortalPageFieldBlur = useCallback((
    _variableConfiguration: IPortalPageVariableConfiguration,
    variable: Variable,
    event?: FocusEvent<HTMLInputElement>,
  ) => {
    const { visualDataType } = variable;

    setPortalPageFormData((previousPortalPageFormData) => {
      if (!isTrimmableString(visualDataType)) {
        return previousPortalPageFormData;
      }

      return {
        ...previousPortalPageFormData,
        [variable.systemName]: (event?.target.value as string)?.trim(),
      };
    });
  }, []);

  const {
    currentData: portalPageElements = [],
    isLoading: isPortalPageElementsLoading,
    isFetching: isPortalPageElementsFetching,
  } = useGetPortalPageElementsQuery(application.status.id);

  const [
    generateImageUrls,
    { isLoading: isGenerateImageUrlsInProgress },
  ] = useGeneratePortalPageImageUrlsMutation();

  const imageUrls = useImageUrls(
    portalPageElements,
    generateImageUrls as GenerateImageUrls,
  );

  const tasksElementExists = useMemo(() => {
    return !!portalPageElements
      .find((element) => element?.elementType === PortalPageElementType.Tasks);
  }, [portalPageElements]);

  const offersSelectionElement = useMemo(() => {
    return portalPageElements
      .find((element) => element && element.elementType === PortalPageElementType.OfferSelection);
  }, [portalPageElements]);

  const eSignatureElement = useMemo(() => {
     return portalPageElements
      .find((element) => element && element.elementType === PortalPageElementType.ESignature);
  }, [portalPageElements]);

  useEffect(() => {
    setOffersVariableValue((prevState) => {
      if (prevState) {
        return prevState;
      }

      return (
        offersSelectionElement && offersVariable
          ? application.variables[offersVariable] as TableValue
          : null
        );
    });
  }, [offersSelectionElement, isSubmitInProgress, offersVariable]);

  useEffect(() => {
    const plaidLinkElement = portalPageElements
      .find((element) => element && element.elementType === PortalPageElementType.PlaidLink);

    dispatch(setIsPlaidElementExists(!!plaidLinkElement));
  }, [portalPageElements]);

  const handleOfferSelect = (updatedOffer: TableValue) => {
    setOffersVariableValue(updatedOffer);
  };

  const [isFindTasksInProgress, handleFindTasks] = useAsyncActionCallback(async (applicationId: string, hasTaskElement: boolean) => {
    if (hasTaskElement && applicationId) {
      await dispatchWithUnwrap(findApplicationTasks({ applicationId }));
    }
  }, []);

  const [, handleSubmitPageData] = useAsyncActionCallback(async () => {
    try {
      dispatch(setApplicationData({
        previousApplicationStatusId: application.status.id,
        isSubmitInProgress: true,
      }));

      await dispatchWithUnwrap(submitPortalPageInfo({
        data: {
          ...portalPageFormData,
          ...(offersVariable ? { [offersVariable]: offersVariableValue } : {}),
        },
        ...(currentApplication?.id === application.id ? {} : { applicationDisplayId: application.displayId }),
      }));
    } catch (error) {
      createNotification({
        notification: t('toastMessages.unableToPerformAction'),
        type: 'error',
        dispatch,
      });
      dispatch(setApplicationData({
        isSubmitInProgress: false,
      }));
    }
  }, [portalPageFormData, application.status.id, offersVariable, offersVariableValue]);

  useEffect(() => {
    handleFindTasks(application.id || '', tasksElementExists);
  }, [application.id, tasksElementExists]);

  useEffect(() => {
    const buttonElement = Object.values(portalPageElements || []).find((element) => {
      return element?.elementType === PortalPageElementType.Button;
    }) as IButtonPageElement | undefined;

    if (buttonElement && buttonElement.config.automationWorkflowId) {
      dispatchWithUnwrap(getAutomationWorkflowById(buttonElement.config.automationWorkflowId));
    }
  }, [portalPageElements]);

  if (isPortalPageElementsLoading || isPortalPageElementsFetching || !portalPageElements) {
    return <LoaderOverlay />;
  }

  if (!isPortalPageElementsLoading && application && portalPageElements.length === 0) {
    return (
      <PageNoAvailable className={pageNotAvailableClassName} />
    );
  }

  return (
    <ApplicationPage
      className={className}
      pageElements={portalPageElements}
      application={application}
      isElementLoadingByType={{
        [PortalPageElementType.Image]: isGenerateImageUrlsInProgress,
        [PortalPageElementType.Tasks]: isFindTasksInProgress,
      } as Record<PortalPageElementType, boolean>}
      portalPageFormData={portalPageFormData}
      onPortalPageFieldChange={handlePortalPageFieldChange}
      onPortalPageFieldBlur={handlePortalPageFieldBlur}
      onSubmitPageData={handleSubmitPageData}
      offersVariableValue={offersSelectionElement ? offersVariableValue : null}
      onOfferSelect={handleOfferSelect}
      offersSelectionElement={(offersSelectionElement || null) as IOfferSelectionPageElement}
      eSignatureElement={(eSignatureElement || null) as IESignatureElement}
      imageUrls={imageUrls}
    />
  );
};

export default ApplicationPageConnector;
