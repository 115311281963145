import { FC } from 'react';
import AuthorizedLayout from 'layout/AuthorizedLayout';
import Button from 'components/digifi-wrappers/Button/Button';
import styles from './AuthorizedCenteredLayout.module.scss';

interface IAuthorizedCenteredLayoutProps {
  title: string;
  subtitle: string | JSX.Element;
  image: JSX.Element;
  applicationsBrowserTitle?: string;
  isLoading?: boolean;
  showButton?: boolean;
  buttonTitle?: string;
  onButtonClick?: () => void;
  hideBorrowerDetails?: boolean;
}

const AuthorizedCenteredLayout: FC<IAuthorizedCenteredLayoutProps> = ({
  title,
  subtitle,
  image,
  applicationsBrowserTitle,
  isLoading,
  showButton,
  buttonTitle,
  onButtonClick,
  hideBorrowerDetails,
}) => {
  return (
    <AuthorizedLayout
      layoutContentClassName={styles.layoutContent}
      applicationsBrowserTitle={applicationsBrowserTitle}
      isLoading={isLoading}
      hideBorrowerDetails={hideBorrowerDetails}
    >
      <div className={styles.contentContainer}>
        <div className={styles.svgContainer}>
          {image}
          <div className={styles.textContainer}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subtitle}>{subtitle}</div>
            {showButton && (
              <Button
                kind="primary"
                size="form"
                onClick={onButtonClick}
              >
                {buttonTitle}
              </Button>
            )}
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default AuthorizedCenteredLayout;
