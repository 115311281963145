import { FC } from 'react';
import clsx from 'clsx';
import TableRow from 'components/digifi-wrappers/TableRow';
import styles from './EmptyTable.module.scss';

const TABLE_ROW_COUNT = 5;
const MIDDLE_ROW_INDEX = Math.floor(TABLE_ROW_COUNT / 2);

interface IEmptyTableProps {
  text: string;
}

const EmptyTable: FC<IEmptyTableProps> = ({
  text,
}) => {
  const renderTable = Array.from({ length: TABLE_ROW_COUNT }).map((value, index) => (
    <TableRow
      key={index}
      className={clsx({ [styles.infoContainer]: index === MIDDLE_ROW_INDEX })}
      disableHover
      hasTopBorder={!index}
    >
      {index === MIDDLE_ROW_INDEX && text}
    </TableRow>
  ));

  return <>{renderTable}</>;
};

export default EmptyTable;
