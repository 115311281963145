import { getSamplePhoneNumber } from './utils';
import { CountryCode } from 'libphonenumber-js';
import TranslationService from 'product_modules/services/TranslationService';

const getDefaultPlaceholder = () => TranslationService.translate('loginDetails.phoneNumber');

const getPhoneNumberPlaceholder = (country: string | null) => {
  if (!country) {
    return getDefaultPlaceholder();
  }

  return getSamplePhoneNumber(country as CountryCode) || getDefaultPlaceholder();
};

export default getPhoneNumberPlaceholder;
