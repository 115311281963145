import { ApplicationStatusType, IApplicationStatus } from 'api/digifi/ApplicationsApi';
import TranslationService from 'product_modules/services/TranslationService';

const maskApplicationStatusName = (status: IApplicationStatus) => {
  switch (status.type) {
    case ApplicationStatusType.Approve:
    case ApplicationStatusType.Reject:
      return status.name;

    default:
      return TranslationService.translate('otherComponents.inProgress');
  }
};

export default maskApplicationStatusName;
