import React, { FC } from 'react';
import clsx from 'clsx';
import { chunk } from 'lodash';
import OverflowedText from 'components/digifi-wrappers/OverflowedText';
import styles from './InfoSection.module.scss';

export interface ISectionContentItem {
  id: string;
  name: string | JSX.Element;
  value: string | JSX.Element | null;
}

interface IInfoSectionProps {
  sectionContent: ISectionContentItem[];
  containerClassName?: string;
  fieldTitleClassName?: string;
  fieldValueClassName?: string;
  fieldClassName?: string;
  rows?: number;
}

const InfoSection: FC<IInfoSectionProps> = ({
  sectionContent,
  containerClassName,
  fieldTitleClassName,
  fieldValueClassName,
  fieldClassName,
  rows = 1,
}) => {
  const rowsSectionContent = chunk(sectionContent, rows);

  const renderField = (item: ISectionContentItem) => {
    return (
      <div key={item.id} className={clsx(styles.field, fieldClassName)}>
        <div className={clsx(styles.fieldTitle, fieldTitleClassName)}>
          <OverflowedText>{item.name || '—'}</OverflowedText>
        </div>
        <div className={clsx(styles.fieldValue, fieldValueClassName)}>
          <OverflowedText>{item.value || '—'}</OverflowedText>
        </div>
      </div>
    );
  };

  const getRowId = (items: ISectionContentItem[]) => {
    return items.map((item) => item.id).join('-');
  };

  return (
    <div className={clsx(styles.sectionContainer, containerClassName)}>
      {rowsSectionContent.map((items) => (
        <div key={getRowId(items)} className={styles.row}>
          {items.map(renderField)}
        </div>
      ))}
      <div className={styles.row}>
        <div className={styles.hiddenField}/>
        <div className={styles.hiddenField}/>
      </div>
    </div>
  );
};

export default InfoSection;
