import React from 'react';
import LoaderWithState, { LoaderState } from 'components/digifi-wrappers/LoaderWithState';
import { FileIcon } from 'static/images';
import { DocumentExtension } from 'api/digifi/DocumentsApi';
import { useAppSelector } from 'hooks/reduxHooks';
import getAbbreviatedString from 'product_modules/utils/getAbbreviatedString';
import { DEFAULT_SNACK_LIFE_DURATION } from 'handlers/snacksSlice';
import { iconsByExtension } from 'components/common/UploadFile/UploadFile';
import styles from 'components/Snackbar/snackbars/ApplicationDocumentDownloadSnackbar/ApplicationDocumentDownloadSnackbar.module.scss';

export interface IApplicationDocumentDownloadSnackbarProps {
  documentName: string;
  documentExtension: DocumentExtension;
  documentDownloadingId: string;
}

const MAX_DOCUMENT_NAME_LENGTH = 35;

const ApplicationDocumentDownloadSnackbar = ({
  documentName,
  documentExtension,
  documentDownloadingId,
}: IApplicationDocumentDownloadSnackbarProps) => {

  const documentDownloadingState = useAppSelector(
    (state) => state.applicationDocuments.documentDownloadingState[documentDownloadingId],
  );

  return (
    <div className={styles.documentContainer}>
      <div className={styles.documentIcon}>{iconsByExtension.get(documentExtension.toLowerCase() as DocumentExtension) || <FileIcon />}</div>
      <div className={styles.documentName}>{getAbbreviatedString(documentName, MAX_DOCUMENT_NAME_LENGTH)}</div>
      <LoaderWithState
        state={
          documentDownloadingState === 'loading' ? LoaderState.Updating : (documentDownloadingState as LoaderState)
        }
        className={styles.loader}
        successTimeout={DEFAULT_SNACK_LIFE_DURATION}
        failureTimeout={DEFAULT_SNACK_LIFE_DURATION}
      />
    </div>
  );
};

export default ApplicationDocumentDownloadSnackbar;
