import { StrictMode } from 'react';
import 'product_modules';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRoutes from './routes';
import { store } from './store';
import {
  googlePlacesApi,
  productCalculationsApi,
  adminAuthApi,
} from 'store/api';
import 'product_modules';
import './index.scss';
import ApiProvider, { IApiProviderValue } from 'providers/ApiServiceProvider';
import SettingsCountryProvider from 'providers/SettingsCountryProvider';
import LoadersProvider from 'product_modules/providers/LoadersProvider';
import GoogleAnalyticsProvider from 'providers/GoogleAnalyticsProvider';
import { VariablesBySystemNameLoader } from 'product_modules/loaders/Variables/VariablesBySystemNameLoader';
import AppTheme from 'AppTheme';
import ErrorBoundary from 'ErrorBoundary';
import LocalizationProvider from 'providers/LocalizationProvider';

const apiProviderValue: IApiProviderValue = {
  googlePlacesApi,
  productCalculationsApi,
  adminAuthApi,
};

const loaders = [{
  component: VariablesBySystemNameLoader,
}];

ReactDOM.render(
  <ErrorBoundary>
    <StrictMode>
      <Provider store={store}>
        <GoogleAnalyticsProvider>
          <SettingsCountryProvider>
            <LoadersProvider loaders={loaders}>
              <ApiProvider value={apiProviderValue}>
                <AppTheme>
                  <LocalizationProvider>
                    <AppRoutes />
                  </LocalizationProvider>
                </AppTheme>
              </ApiProvider>
            </LoadersProvider>
          </SettingsCountryProvider>
        </GoogleAnalyticsProvider>
      </Provider>
    </StrictMode>
  </ErrorBoundary>,
  document.getElementById('root'),
);
