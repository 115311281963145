import { detect } from 'detect-browser';
import { throttle } from 'lodash';
import LoggerApi, { IBrowserInfo } from 'api/LoggerApi';

const THROTTLE_DELAY = 5000;

const UNKNOWN_BROWSER: IBrowserInfo = {
  name: 'unknown',
  os: 'unknown',
  type: 'unknown',
  version: 'unknown',
};

class LoggerService {
  protected errors: Error[] = [];

  protected browserInfo: IBrowserInfo;

  protected loggerApi = new LoggerApi();

  constructor() {
    const detectedInfo = detect();

    this.browserInfo = {
      name: detectedInfo?.name || UNKNOWN_BROWSER.name,
      os: detectedInfo?.os || UNKNOWN_BROWSER.os,
      type: detectedInfo?.type || UNKNOWN_BROWSER.type,
      version: detectedInfo?.version || UNKNOWN_BROWSER.version,
    };
  }

  public log(error: Error) {
    this.errors = [...this.errors, error];

    this.sendLogs();
  }

  protected sendLogs = throttle(() => {
    this.loggerApi.log(this.errors.map(this.formatMessage), this.browserInfo);
    this.errors = [];
  }, THROTTLE_DELAY);

  protected formatMessage(error: Error): string {
    return error.stack || error.message;
  }
}

export default LoggerService;
