import { nanoid } from 'nanoid';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import { IApplicationDocument } from 'api/digifi/DocumentsApi';
import ApplicationDetails from 'components/ApplicationDetails/ApplicationDetails';
import { SnackbarComponentTypes } from 'components/Snackbar/Types';
import { AppRoute } from 'enums/AppRoute';
import { downloadAllDocuments, downloadApplicationDocument, getApplicationDocuments } from 'handlers/documentsSlice';
import { removeSnackTimer, showSnack } from 'handlers/snacksSlice';
import { useAppSelector } from 'hooks/reduxHooks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

interface IApplicationDetailsConnectorProps {
  application: IApplicationViewModel | null;
}

const ApplicationDetailsConnector: FC<IApplicationDetailsConnectorProps> = ({
  application,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const documents = useAppSelector((state) => state.applicationDocuments.entities) as Record<string, IApplicationDocument>;
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDownloadAllDocuments = async () => {
    const id = nanoid();

    if (!application) {
      return;
    }

    dispatch(showSnack({
      id,
      title: t('applicationDetails.downloadingDocument'),
      content: {
        componentType: SnackbarComponentTypes.ApplicationDownloadDocumentsComponent,
        props: {
          documentName: `${t('applicationDetails.applicationDocuments')} - ${application.displayId}`,
          documentDownloadingId: id,
        },
      },
    }));

    await dispatchWithUnwrap(downloadAllDocuments({ applicationId: application.id, id }));

    removeSnackTimer(id, dispatch);
  };

  const loadDocuments = async () => {
    setIsLoading(true);

    if (application) {
      await dispatchWithUnwrap(getApplicationDocuments({
        applicationId: application.id,
      }));
    }

    setIsLoading(false);
  };

  const handleDocumentDownload = async (document: IApplicationDocument) => {
    dispatch(showSnack({
      id: document.id,
      title: t('applicationDetails.downloadingDocument'),
      content: {
        componentType: SnackbarComponentTypes.ApplicationDownloadDocumentComponent,
        props: {
          documentName: document.name,
          documentExtension: document.extension,
          documentDownloadingId: document.id,
        },
      },
    }));

    await dispatchWithUnwrap(downloadApplicationDocument(document.id));

    removeSnackTimer(document.id, dispatch);
  };

  const applicationDocuments = Object.values(documents).filter((document) => document.applicationId === application?.id);

  const handleOpenDocument = (document: IApplicationDocument) => {
    navigate(`${AppRoute.DocumentPreview}/${document.extension}/${document.id}/${document.name}`);
  };

  useEffect(() => {
    loadDocuments();
  }, []);

  return (
    <ApplicationDetails
      application={application}
      documents={isLoading ? null: applicationDocuments}
      onAllDocumentsDownload={handleDownloadAllDocuments}
      onOpenDocument={handleOpenDocument}
      onDocumentDownload={handleDocumentDownload}
    />
  );
};

export default ApplicationDetailsConnector;
