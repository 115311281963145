import BaseApi from 'api/BaseApi';

export enum BrandingLanguage {
  EN = 'en',
  DA = 'da',
  NL = 'nl',
  FI = 'fi',
  FR = 'fr',
  DE = 'de',
  HU = 'hu',
  IT = 'it',
  NB = 'nb',
  PT = 'pt',
  RO = 'ro',
  RU = 'ru',
  ES = 'es',
  SV = 'sv',
  TR = 'tr',
}

interface IGetBrandingResponse {
  brandColor: string,
  accentColor: string;
  faviconId: string;
  language: BrandingLanguage;
  logoId: string;
  companyName: string;
  country: string;
}

interface IGetPortalConfigurationResponse {
  borrowerLockPeriodDays: number;
}

interface IGetVariablesSettingsResponse {
  phoneNumberFormat: string;
  currency: string;
}

export interface ILegalDocument {
  id: string;
  name: string;
  body: string;
  showInFooter: boolean;
  showOnSubmitApplication: boolean;
  updatedAt: string;
}

export interface ISettingsApi {
  getBranding(): Promise<IGetBrandingResponse | undefined>;
  getLegalDocuments(): Promise<ILegalDocument[]>;
  getPortalConfiguration(): Promise<IGetPortalConfigurationResponse>;
  getVariablesSettings(): Promise<IGetVariablesSettingsResponse>;
}

export default class SettingsRestApi extends BaseApi implements ISettingsApi {
  public getBranding(): Promise<IGetBrandingResponse> {
    return this.fetch<IGetBrandingResponse>('/settings/branding');
  };

  public getLegalDocuments(): Promise<ILegalDocument[]> {
    return this.fetch('/settings/legal-documents');
  }

  public getPortalConfiguration(): Promise<IGetPortalConfigurationResponse> {
    return this.fetch('/settings/portal-configuration');
  }

  public getVariablesSettings(): Promise<IGetVariablesSettingsResponse> {
    return this.fetch('/variables');
  }
}
