import { forwardRef } from 'react';
import clsx from 'clsx';
import ButtonWithLoadingState , { ButtonWithLoadingStateProps } from 'product_modules/components/ButtonWithLoadingState';
import StyleButtonComponent, { getButtonClassName } from 'components/digifi-wrappers/Button/StyleButtonComponent';

const ButtonWithLoadingStateStyled = StyleButtonComponent(ButtonWithLoadingState, 'root');

const ButtonWithLoadingStateWrapper = forwardRef<HTMLButtonElement, ButtonWithLoadingStateProps>(({ className, ...props }, ref) => {
  return (
    <ButtonWithLoadingStateStyled
      {...props}
      ref={ref}
      className={clsx(getButtonClassName(props.kind), className)}
    />
  );
});

export default ButtonWithLoadingStateWrapper;
