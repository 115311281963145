import { FC } from 'react';
import clsx from 'clsx';
import { YellowCircleExclamationMarkIcon } from 'static/images';
import styles from './TestModeNotification.module.scss';

interface ITestModeNotificationProps {
  message: string;
  title: string;
  className?: string;
}

const TestModeNotification: FC<ITestModeNotificationProps> = ({
  className,
  message,
  title,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <YellowCircleExclamationMarkIcon className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{title}</div>
          <div className={styles.hint}>test mode hint</div>
        </div>
        <div className={styles.message}>{message}</div>
      </div>
    </div>
  );
};

export default TestModeNotification;
