import { FC, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { matchPath, useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks/reduxHooks';
import { AppRouteWithParams } from 'enums/AppRouteWithParams';
import MontserratRegularFontSrc from 'static/fonts/Montserrat/Montserrat-Regular.ttf';
import MontserratBoldFontSrc from 'static/fonts/Montserrat/Montserrat-Bold.ttf';
import TranslationService from 'product_modules/services/TranslationService';

interface ICustomHtmlHeadProps {
  applicationsBrowserTitle?: string;
}

const ROUTE_TITLE_MAP: Record<string, string> = {
  [AppRouteWithParams.CreateAccount]: TranslationService.translate('createAccount.createAccount'),
  [AppRouteWithParams.SignIn]: TranslationService.translate('common.signIn'),
  [AppRouteWithParams.AcceptInvite]: TranslationService.translate('common.acceptInvite'),
  [AppRouteWithParams.PhoneVerification]: TranslationService.translate('phoneAuthVerification.phoneVerification'),
  [AppRouteWithParams.ConfirmEmail]: TranslationService.translate('common.confirmEmail'),
  [AppRouteWithParams.ResetPassword]: TranslationService.translate('common.resetPassword'),
  [AppRouteWithParams.CreateNewPassword]: TranslationService.translate('resetPassword.createNewPassword'),
};

const CustomHtmlHead: FC<ICustomHtmlHeadProps> = ({ applicationsBrowserTitle }) => {
  const { faviconId, companyName } = useAppSelector((state) => state.settings.branding);
  const [browserTitle, setBrowserTitle] = useState<string>(companyName || '');
  const location = useLocation();

  const getCompanyNamePart = () => {
    return companyName ? `| ${companyName}` : '';
  };

  useEffect(() => {
    const routeTitleMap = Object.entries(ROUTE_TITLE_MAP)
      .find(([ path ]) => matchPath(path, location.pathname));

    const [, routeTitle] = routeTitleMap || [];

    if (routeTitle) {
      return setBrowserTitle(`${routeTitle} ${getCompanyNamePart()}`);
    }

    if (applicationsBrowserTitle) {
      return setBrowserTitle(`${applicationsBrowserTitle} ${getCompanyNamePart()}`);
    }

    return setBrowserTitle(companyName || '');
  }, [location, applicationsBrowserTitle]);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="shortcut icon" id="favicon" href={faviconId ? `/api/branding/favicon/${faviconId}` : 'data:;base64,='} />
        <link rel="preload" href={MontserratBoldFontSrc} as="font" type="font/ttf" />
        <link rel="preload" href={MontserratRegularFontSrc} as="font" type="font/ttf" />
        <title>{browserTitle}</title>
      </Helmet>
    </HelmetProvider>
  );
};

export default CustomHtmlHead;
