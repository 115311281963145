import { forwardRef } from 'react';
import clsx from 'clsx';
import { ReactNode } from 'react';
import PopUp from 'components/digifi-wrappers/PopUp';
import PopUpContent from 'components/digifi-wrappers/PopUpContent';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import styles from './ModalOverlay.module.scss';

export interface IModalOverlayProps {
  title: string | React.ReactNode;
  onClose: () => void;
  headerClassName?: string;
  headerContent?: JSX.Element | null;
  headerFixedAtTop?: boolean;
  isLoading?: boolean;
  className?: string;
  titleClassName?: string;
  closeIconClassName?: string;
  children?: ReactNode;
  sectionClassName?: string;
  contentClassName?: string;
  usePortal?: boolean
}

const ModalOverlay = forwardRef<HTMLElement, IModalOverlayProps>(({
  title,
  onClose,
  headerContent,
  headerClassName,
  isLoading,
  children,
  className,
  closeIconClassName,
  titleClassName,
  sectionClassName,
  contentClassName,
  usePortal,
}, ref) => {
  const renderTitleSkeleton = () => {
    return (
      <div className={styles.skeletonContainer}>
        <SkeletonText width="580px" height="24px" color="primary20" lineHeight="44px" className={styles.desktopTitle} />
        <SkeletonText width="95%" height="20px" color="primary20" lineHeight="36px" className={styles.mobileTitle} />
      </div>
    );
  };

  return (
    <PopUp
      title={!isLoading ? title : renderTitleSkeleton()}
      subTitle={headerContent}
      closePopUp={onClose}
      classNames={{
        sectionClassName: clsx(styles.popUpContainer, sectionClassName),
        header: clsx(styles.popUpHeader, headerClassName),
        popup: className,
        closeIcon: clsx(styles.closeIcon, closeIconClassName),
        title: clsx(styles.title, titleClassName),
      }}
      ref={ref}
      usePortal={usePortal}
    >
      <PopUpContent className={clsx(styles.popUpContent, contentClassName)}>
        {children}
      </PopUpContent>
    </PopUp>
  );
});

export default ModalOverlay;
