import { useTranslation } from 'react-i18next';
import { FC, useEffect } from 'react';
import AuthorizedLayout from 'layout/AuthorizedLayout';
import ApplicationHistory from 'components/Home/ApplicationHistory';
import CurrentApplicationBlock from 'components/Home/CurrentApplicationBlock';
import AuthorizedPageWrapper from 'pages/applications/AuthorizedPageWrapper/AuthorizedPageWrapper';
import ProductSelector from 'components/Home/ProductSelector/ProductSelector';
import { useAppDispatch } from 'hooks/reduxHooks';
import { resetSelectedApplication } from 'handlers/applicationDataSlice';
import styles from './HomePage.module.scss';

const HomePage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetSelectedApplication());
  }, []);

  return (
    <AuthorizedLayout
      applicationsBrowserTitle={t('header.home')}
      innerContentClassName={styles.innerContent}
      layoutContentClassName={styles.layoutContent}
    >
      <AuthorizedPageWrapper>
        <div className={styles.container}>
          <div className={styles.title}>{t('header.home')}</div>
          <CurrentApplicationBlock />
          <ApplicationHistory />
        </div>
        <ProductSelector />
      </AuthorizedPageWrapper>
    </AuthorizedLayout>
  );
};

export default HomePage;
