import { Trans, useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ForbiddenImage } from 'static/images';
import AuthorizedCenteredLayout from 'layout/AuthorizedCenteredLayout';

const ForbiddenPage: FC= () => {
  const { t } = useTranslation();

  const renderSubtitle = () => {
    return (
      <Trans i18nKey="404&NoAccess.accessDeniedSubtitle">
        You do not have access to your account at this time.<br />Please contact us for more details.
      </Trans>
    );
  };

  return (
   <AuthorizedCenteredLayout
     title={t('404&NoAccess.accessDenied')}
     subtitle={renderSubtitle()}
     image={<ForbiddenImage />}
     applicationsBrowserTitle={t('404&NoAccess.accessDenied')}
   />
  );
};

export default ForbiddenPage;
