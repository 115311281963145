import TranslationService from "product_modules/services/TranslationService";

interface GetTableRowActionsProps {
  index: number;
  id: string;
  onRowClear: (rowIndex: number, rowId: string) => void;
  onRowDelete: (rowIndex: number, rowId: string) => void;
  onRowInsert: (rowIndex: number) => void;
  onRowDuplicate: (rowIndex: number, rowId: string) => void;
  tableIsMaxLength?: boolean;
  tableHasOneRow?: boolean;
}

const getTableRowActions = ({
  index,
  id,
  onRowClear,
  onRowDelete,
  onRowInsert,
  onRowDuplicate,
  tableIsMaxLength = false,
  tableHasOneRow = false,
}: GetTableRowActionsProps) => {
  return [
    {
      title: TranslationService.translate('otherComponents.insertRowAbove'),
      handler: () => onRowInsert(index),
      disabled: tableIsMaxLength,
    },
    {
      title: TranslationService.translate('otherComponents.insertRowBelow'),
      handler: () => onRowInsert(index + 1),
      disabled: tableIsMaxLength,
    },
    {
      title: TranslationService.translate('otherComponents.duplicateRow'),
      handler: () => onRowDuplicate(index, id),
      disabled: tableIsMaxLength,
    },
    {
      title: TranslationService.translate('otherComponents.clearRow'),
      handler: () => onRowClear(index, id),
      separatorRequired: true,
    },
    ...(!tableHasOneRow ? [{
      title: TranslationService.translate('otherComponents.deleteRow'),
      handler: () => onRowDelete(index, id),
      danger: true,
    }] : []),
  ];
};

export default getTableRowActions;
