import { FC } from 'react';
import clsx from 'clsx';
import Footer from 'components/PageLayout/Footer';
import Notifications from 'components/Notifications/NotificationsLayout/Notifications';
import { useAppSelector } from 'hooks/reduxHooks';
import LegalDocumentPopUp from 'components/LegalDocumentPopUp';
import CustomHtmlHead from 'components/PageLayout/CustomHtmlHead';
import { Snackbar } from 'components/Snackbar/ConnectedSnackbar';
import TestModeNotificationList from 'components/TestModeNotificationList';
import { ITestModeNotificationItems } from 'components/TestModeNotificationList/TestModeNotificationList';
import styles from './MainLayout.module.scss';

interface IMAinLayoutProps {
  disableScroll?: boolean;
  applicationsBrowserTitle?: string;
  contentClassName?: string;
  innerContentClassName?: string;
  testModeNotifications?: ITestModeNotificationItems[];
  renderElementBeforeContent?: () => React.ReactElement;
}

const MainLayout: FC<IMAinLayoutProps> = ({
  disableScroll,
  applicationsBrowserTitle,
  contentClassName,
  innerContentClassName,
  testModeNotifications,
  children,
  renderElementBeforeContent,
}) => {
  const { selectedDocument } = useAppSelector((state) => state.settings);

  const renderLegalDocumentsPopUp = () => {
    return <LegalDocumentPopUp
      name={selectedDocument?.name!}
      body={selectedDocument?.body!}
      updatedAt={selectedDocument?.updatedAt!}
    />;
  };

  return (
    <div className={clsx({ [styles.mainLayoutContainerDisabled]: selectedDocument || disableScroll })}>
      <CustomHtmlHead applicationsBrowserTitle={applicationsBrowserTitle} />
      <div className={clsx(styles.mainLayoutContent, contentClassName)}>
        {renderElementBeforeContent?.()}
        <div className={clsx(styles.innerContent, innerContentClassName)}>
          {children}
        </div>
        {testModeNotifications && (
          <TestModeNotificationList
            className={styles.testModeNotificationList}
            notificationClassName={styles.testModeNotification}
            items={testModeNotifications}
          />
        )}
        <Footer />
        <Notifications />
        <Snackbar />
      </div>
      {selectedDocument && renderLegalDocumentsPopUp()}
    </div>
  );
};

export default MainLayout;
