import React, { FC, ReactNode } from 'react';
import { CloseButtonImage } from 'static/images';
import { useAppDispatch } from 'hooks/reduxHooks';
import { hideSnack } from 'handlers/snacksSlice';
import styles from 'components/Snackbar/Snack/Snack.module.scss';

interface ISnackProps {
  children: ReactNode;
  snackId: string;
  title: string;
}

const Snack: FC<ISnackProps> = ({ children, snackId, title }) => {
  const dispatch = useAppDispatch();

  const handleHideSnack = () => {
    dispatch(hideSnack(snackId));
  };

  return (
    <div className={styles.snackContainer}>
      <div className={styles.snackHeader}>
        <div>{title}</div>
        <button type="button" onClick={handleHideSnack} className={styles.closeButton}>
          <CloseButtonImage />
        </button>
      </div>
      <div className={styles.snackBody}>{children}</div>
    </div>
  );
};

export default Snack;
