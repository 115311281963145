import { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import TableHead from 'components/digifi-wrappers/TableHead';
import TableHeadCell from 'components/digifi-wrappers/TableHeadCell';
import TableBody from 'components/digifi-wrappers/TableBody';
import {
  DocumentsTableColumnSizes,
  DocumentsTableColumnSizesMobile,
} from 'components/ApplicationDetails/DocumentsTableRow/DocumentsTableColumnSizes';
import DocumentsTableRow from 'components/ApplicationDetails/DocumentsTableRow/DocumentsTableRow';
import {
  ApplicationDocumentsSortingField,
  ApplicationDocumentsSortingType,
  IApplicationDocument,
} from 'api/digifi/DocumentsApi';
import SkeletonDocumentsTable from 'components/ApplicationDetails/DocumentsTable/SkeletonDocumentsTable';
import EmptyTable from 'components/ApplicationDetails/EmptyTable';
import TableRow from 'components/digifi-wrappers/TableRow';
import TableBodyCell from 'components/digifi-wrappers/TableBodyCell';
import useSorting from 'product_modules/hooks/useSorting';
import ButtonWithImage from 'components/digifi-wrappers/ButtonWithImage';
import styles from './DocumentsTable.module.scss';

interface IDocumentsTableProps {
  sortingType: ApplicationDocumentsSortingType;
  documents: IApplicationDocument[] | null;
  onSort: (field: ApplicationDocumentsSortingField, ascending: boolean) => void;
  onAllDocumentsDownloadClick: () => void;
  onDocumentDownloadClick: (document: IApplicationDocument) => void;
  onDocumentClick: (document: IApplicationDocument) => void;
}

const DocumentsTable: FC<IDocumentsTableProps> = ({
  sortingType,
  documents,
  onAllDocumentsDownloadClick,
  onDocumentDownloadClick,
  onDocumentClick,
  onSort,
}) => {
  const { t }= useTranslation();
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderDownloadAllDocumentsRow = () => {
    return (
      <TableRow disableHover>
        <TableBodyCell className={styles.downloadAllCell} onClick={onAllDocumentsDownloadClick} noPadding>
          <ButtonWithImage
              title={t('applicationDetails.downloadAllDocuments')}
              titleClassName={styles.downloadAllCellTitle}
            />
        </TableBodyCell>
      </TableRow>
    );
  };

  const renderTableBody = () => {
    if (!documents) {
      return <SkeletonDocumentsTable />;
    }

    if (documents.length === 0) {
      return <EmptyTable text={t('applicationDetails.noDocuments')} />;
    }

    return (
      <TableBody>
        {documents.map((document, index) => (
          <DocumentsTableRow
            key={`${document.name}_${index}`}
            document={document}
            onDocumentDownloadClick={onDocumentDownloadClick}
            onDocumentClick={onDocumentClick}
            hasTopBorder
          />
        ))}
        {renderDownloadAllDocumentsRow()}
      </TableBody>
    );
  };

  const isLoading = documents === null;

  const renderDesktopHead = () => {
    return (
      <>
        <TableHead className={clsx(styles.tableHead, styles.tableRowDesktop)}>
          <TableHeadCell
            width={DocumentsTableColumnSizes.Name}
            ascending={!isLoading && documents?.length ? getSortingType(ApplicationDocumentsSortingField.Name) : undefined}
            onClick={!isLoading && documents?.length ? () => changeSorting(ApplicationDocumentsSortingField.Name) : undefined}
          >
            {t('applicationDetails.name')}
          </TableHeadCell>
          <TableHeadCell
            width={DocumentsTableColumnSizes.Type}
            ascending={!isLoading && documents?.length ? getSortingType(ApplicationDocumentsSortingField.Extension) : undefined}
            onClick={!isLoading && documents?.length ? () => changeSorting(ApplicationDocumentsSortingField.Extension) : undefined}
          >
            {t('applicationDetails.type')}
          </TableHeadCell>
          <TableHeadCell
            width={DocumentsTableColumnSizes.Size}
            ascending={!isLoading && documents?.length ? getSortingType(ApplicationDocumentsSortingField.Size) : undefined}
            onClick={!isLoading && documents?.length ? () => changeSorting(ApplicationDocumentsSortingField.Size) : undefined}
          >
            {t('applicationDetails.size')}
          </TableHeadCell>
          <TableHeadCell
            width={DocumentsTableColumnSizes.Uploaded}
            ascending={!isLoading && documents?.length ? getSortingType(ApplicationDocumentsSortingField.CreatedAt) : undefined}
            onClick={!isLoading && documents?.length ? () => changeSorting(ApplicationDocumentsSortingField.CreatedAt) : undefined}
          >
            {t('applicationDetails.uploaded')}
          </TableHeadCell>
        </TableHead>
      </>
    );
  };

  const renderMobileHead = () => {
    return (
      <>
        <TableHead className={clsx(styles.tableHead, styles.tableRowMobile)}>
          <TableHeadCell
            width={DocumentsTableColumnSizesMobile.Name}
            ascending={!isLoading && documents?.length ? getSortingType(ApplicationDocumentsSortingField.Name) : undefined}
            onClick={!isLoading && documents?.length ? () => changeSorting(ApplicationDocumentsSortingField.Name) : undefined}
          >
            {t('applicationDetails.name')}
          </TableHeadCell>
          <TableHeadCell
            width={DocumentsTableColumnSizesMobile.Size}
            ascending={!isLoading && documents?.length ? getSortingType(ApplicationDocumentsSortingField.Size) : undefined}
            onClick={!isLoading && documents?.length ? () => changeSorting(ApplicationDocumentsSortingField.Size) : undefined}
          >
            {t('applicationDetails.size')}
          </TableHeadCell>
          <TableHeadCell width={DocumentsTableColumnSizesMobile.Uploaded} />
        </TableHead>
      </>
    );
  };

  return (
    <>
      {renderDesktopHead()}
      {renderMobileHead()}
      {renderTableBody()}
    </>
  );
};

export default DocumentsTable;
