import { FC, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useAppSelector } from 'hooks/reduxHooks';
import { IAppTheme } from 'types/AppTheme';
import { getBackgroundHoverColor, getHoverColor } from 'utils/branding/brandingHelpers';
import colors from 'styles/colors.module.scss';

const DEFAULT_THEME: IAppTheme = {
  colors: {
    accentColor: '',
    brandColor: '',
    backgroundHoverAccentColor: '',
    hoverAccentColor: '',
    white100Base: colors.white100Base,
    backgroundHoverBrandColor: '',
    hoverBrandColor: '',
  },
};

const AppTheme: FC = ({ children }) => {
  const [theme, setTheme] = useState<IAppTheme>(DEFAULT_THEME);
  const {
    accentColor,
    brandColor,
  } = useAppSelector((state) => state.settings.branding);

  useEffect(() => {
    if (accentColor && brandColor) {
      setTheme({
        colors: {
          ...theme.colors,
          accentColor: accentColor,
          brandColor: brandColor,
          backgroundHoverAccentColor: getBackgroundHoverColor(accentColor),
          hoverAccentColor: getHoverColor(accentColor),
          backgroundHoverBrandColor: getBackgroundHoverColor(brandColor),
          hoverBrandColor: getHoverColor(brandColor),
        },
      });
    }
  }, [accentColor, brandColor]);

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default AppTheme;
