import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import ProductsRestApi, { IProduct } from 'api/digifi/ProductsApi';
import { RootState } from 'store';
import rejectWithValue from 'utils/rejectWithValue';

enum ProductsActionType {
  GetProducts = 'products/getProducts',
}

const productsAdapter = createEntityAdapter<IProduct>();

export interface IProductsState extends EntityState<IProduct> {
}

const initialState: IProductsState = productsAdapter.getInitialState();

const productsApi = new ProductsRestApi();

export const getAllAvailableProducts = createAsyncThunk(
  ProductsActionType.GetProducts,
  async(_, thunkApi) => {
    try {
      return await productsApi.getAllAvailableProducts();
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

const productsSlice = createSlice({
  name: 'productsSlice',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(getAllAvailableProducts.fulfilled, (state, { payload }) => {
        productsAdapter.setAll(state, payload);
      });
  },
});

export const { selectAll: selectAllProducts } = productsAdapter.getSelectors((state: RootState) => state.products);

export default productsSlice.reducer;
