import { isValidPhoneNumber } from 'react-phone-number-input';
import getMessage, { MessageType } from 'constants/Messages';

const ID_NUMBER_DEFAULT_LENGTH = 9;
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i;

export const isEmptyField = (value: string): boolean => !value.trim();

export const removeWhiteSpace = <Value>(value: Value): Value | string => {
  if (typeof value === 'string') {
    return value.replace(/^\s+/, '');
  }

  return value;
};

export const validateEmail = (email: string): string => {
  return email && EMAIL_REGEX.test(email) ? '' : getMessage(MessageType.EmailInvalid);
};

export const validateIDNumber = (value: string, template?: string) => {
  if (!template || template === 'Other') {
    return '';
  }

  return value.length === ID_NUMBER_DEFAULT_LENGTH ? '' : getMessage(MessageType.IdentificationNumberInvalid);
};

export const validatePhoneNumber = (phoneNumber: string) => {
  return isValidPhoneNumber(phoneNumber) ? '' : getMessage(MessageType.PhoneNumberInvalid);
};

const validateNumberCap = (
  value: string | number,
  cap: string | null | undefined,
  minCap?: boolean,
  formatCap?: (cap: string) => string,
) => {
  if (!cap) {
    return '';
  }

  const numberValue = typeof value === 'string' ? Number.parseFloat(value) : value;
  const numberCap = Number.parseFloat(cap);

  const formattedCap = formatCap ? formatCap(cap) : cap;

  if (!minCap) {
    return numberValue <= numberCap ? '' : getMessage(MessageType.MaximumValue, { cap: formattedCap });
  }

  return numberValue >= numberCap ? '' : getMessage(MessageType.MinimumValue, { cap: formattedCap });
};

const validateDateCap = (
  value: string,
  cap: string | null | undefined,
  minCap?: boolean,
  formatCap?: (cap: string) => string,
) => {
  if (!cap) {
    return '';
  }

  const dateCap = Date.parse(cap);
  const dateValue = Date.parse(value);

  const formattedCap = formatCap ? formatCap(cap) : cap;

  if (!minCap) {
    return dateValue <= dateCap ? '' : getMessage(MessageType.MaximumValue, { cap: formattedCap });
  }

  return dateValue >= dateCap ? '' : getMessage(MessageType.MinimumValue, { cap: formattedCap });
};

export const validateMinNumberCap = (
  value: string | number,
  minAllowedValue: string | null | undefined,
  formatCap?: (cap: string) => string,
) => {
  return validateNumberCap(value, minAllowedValue, true, formatCap);
};

export const validateMaxNumberCap = (
  value: string | number,
  maxAllowedValue: string | null | undefined,
  formatCap?: (cap: string) => string,
) => {
  return validateNumberCap(value, maxAllowedValue, false, formatCap);
};

export const validateMinDateCap = (
  value: string,
  minAllowedValue: string | null | undefined,
  formatCap?: (cap: string) => string,
) => {
  return validateDateCap(value, minAllowedValue, true, formatCap);
};

export const validateMaxDateCap = (
  value: string,
  maxAllowedValue: string | null | undefined,
  formatCap?: (cap: string) => string,
) => {
  return validateDateCap(value, maxAllowedValue, false, formatCap);
};

export const validatePassword = (password: string): MessageType | null => {
  if (password.length === 0) {
    return MessageType.PasswordRequired;
  }

  if (password.length < 8 || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/[0-9]/.test(password)) {
    return MessageType.PasswordRequirementsNotMet;
  }

  return null;
};
