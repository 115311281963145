import { useTranslation } from 'react-i18next';
import BaseIntegrationElement from 'components/PortalPageElements/BaseIntegrationElement';
import { DocuSignLogo } from 'static/images';

const WaitingForOthers = () => {
  const { t } = useTranslation();
  
  return (
    <BaseIntegrationElement
      logo={<DocuSignLogo />}
      title={t('pageBuilderElements.waitingForOthers')}
      description={t('pageBuilderElements.waitingForOthersPrompt')}
    />
  );
};

export default WaitingForOthers;
