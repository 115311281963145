import React, { FC, useRef } from 'react';
import { FormikErrors } from 'formik';
import TextInput from 'components/digifi-wrappers/TextInput';
import { MessageType } from 'constants/Messages';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';

interface ICreatePersonAccountFormParams {
  firstName: string;
  lastName: string;
}

interface ICreateAccountFormPersonProps {
  errors: FormikErrors<ICreatePersonAccountFormParams>;
  values: ICreatePersonAccountFormParams;
  clearFieldErrorOnFocus: (name: string) => void;
  onBlur: (name: string, errorMessage: MessageType, value: string) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const BorrowerNamePerson: FC<ICreateAccountFormPersonProps> = ({ clearFieldErrorOnFocus, onBlur, onChange, onKeyDown, errors, values, isLoading }) => {
  const lastNameInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <TextInput
        labelTitle="First Name"
        placeholder="First Name"
        required
        disabled={isLoading}
        errorMessage={errors.firstName}
        name="firstName"
        onChange={onChange}
        onFocus={() => clearFieldErrorOnFocus('firstName')}
        onBlur={() => onBlur('firstName', MessageType.FirstNameRequired, values.firstName)}
        value={values.firstName}
        onKeyDown={TriggerEventOnEnterKeyDown(lastNameInputRef, 'focus')}
      />
      <TextInput
        labelTitle="Last Name"
        placeholder="Last Name"
        required
        disabled={isLoading}
        errorMessage={errors.lastName}
        name="lastName"
        onChange={onChange}
        onFocus={() => clearFieldErrorOnFocus('lastName')}
        onBlur={() => onBlur('lastName', MessageType.LastNameRequired, values.lastName)}
        value={values.lastName}
        ref={lastNameInputRef}
        onKeyDown={onKeyDown}
      />
    </>
  );
};

export default BorrowerNamePerson;
