import { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import styles from './OfferSelectionElement.module.scss';

interface IOfferNotAvailableProps {
  containerClassName?: string;
}

const OfferNotAvailable: FC<IOfferNotAvailableProps> = ({ containerClassName }) => {
  const { t } = useTranslation();
  
  return (
    <div className={clsx(styles.offerNotAvailableContainer, containerClassName)}>
      <p className={styles.offerNotAvailableTitle}>{t('pageBuilderElements.noOffersAvailable')}</p>
      <p className={styles.description}>{t('pageBuilderElements.noOffersAvailablePrompt')}</p>
    </div>
  );
};

export default OfferNotAvailable;
