import { FC, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { HandleKeyDown } from 'product_modules/utils/TriggerEventOnEnterKeyDown';
import KeyEventEnum from 'product_modules/common/KeyEventEnum';
import { ILegalDocument } from 'api/digifi/SettingsApi';
import LinkButton from 'components/digifi-wrappers/LinkButton';
import Checkbox from 'components/digifi-wrappers/Checkbox/Checkbox';

interface ILegalConsentsCheckboxProps {
  legalDocuments: ILegalDocument[];
  onLegalDocumentClick: (legalDocument: ILegalDocument) => void;
  onCheck: () => void;
  isChecked: boolean;
  className?: string;
}

const LegalConsentsCheckbox: FC<ILegalConsentsCheckboxProps> = ({
  legalDocuments,
  onLegalDocumentClick,
  onCheck,
  isChecked,
  className,
}) => {
  const LegalDocuments = () => {
    return <>{renderLegalDocuments()}</>;
  };

  const renderCheckboxLabel = () => {
    return (
      <Trans i18nKey='common.confirmConsent'>
        By checking this box I confirm that I have read, understood, and consent to
        <LegalDocuments />
      </Trans>
    );
  };

  const renderLegalDocuments = () => {
    return legalDocuments.map((document, index) => (
      <Fragment key={`document.name_${index}`}>
        <LinkButton
          onClick={(event) => {
            event.preventDefault();
            onLegalDocumentClick(document);
          }}
        >
          {document.name}
          {index <= legalDocuments.length - 2 && ','}
        </LinkButton>
        {index === legalDocuments.length - 1 ? '' : ' '}
      </Fragment>
    ));
  };

  return (
    <div className={className}>
      <Checkbox
        label={renderCheckboxLabel()}
        checked={isChecked}
        onChange={onCheck}
        onKeyDown={HandleKeyDown(KeyEventEnum.Enter, onCheck)}
      />
    </div>
  );
};

export default LegalConsentsCheckbox;
