import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { NotFoundSignImage } from 'static/images';
import { AppRoute } from 'enums/AppRoute';
import { getBorrower } from 'handlers/borrowersSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import AuthorizedCenteredLayout from 'layout/AuthorizedCenteredLayout';

const NotFoundPage: FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const fetchData = async () => {
    setIsLoading(true);

    await dispatchWithUnwrap(getBorrower());
  };

  useEffect(() => {
    fetchData().finally(() => setIsLoading(false));
  }, []);

  const handleClick = () => {
    navigate(AppRoute.Home);
  };

  const renderSubtitle = () => {
    return (
      <Trans i18nKey='404&NoAccess.pageNotFoundDescription'>
        We were unable to find the page you requested.
        <br />
        Please check the URL, go back or return to our home page.
      </Trans>
    );
  };

  return (
    <AuthorizedCenteredLayout
      title={t('404&NoAccess.pageNotFoundTitle')}
      subtitle={renderSubtitle()}
      image={<NotFoundSignImage />}
      showButton
      buttonTitle={t('404&NoAccess.buttonTitle')}
      onButtonClick={handleClick}
      isLoading={isLoading}
      applicationsBrowserTitle={t('404&NoAccess.browserTitle')}
    />
  );
};

export default NotFoundPage;
