import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow } from 'product_modules/components/Table';
import TableHead from 'components/digifi-wrappers/TableHead';
import TableHeadCell from 'components/digifi-wrappers/TableHeadCell';
import TableBody from 'components/digifi-wrappers/TableBody';
import { ApplicationsTableRow } from 'components/ApplicationsList/ApplicationsTableRow';
import { ApplicationsTableColumnSizes } from 'components/ApplicationsList/ApplicationsTableRow';
import { ApplicationSortingField, ApplicationsSortingType, IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import useSorting from 'product_modules/hooks/useSorting';
import { SkeletonApplicationsTable } from 'components/ApplicationsList/ApplicationsTable';
import EmptyTable from 'components/ApplicationDetails/EmptyTable';
import styles from './ApplicationsTable.module.scss';

interface IApplicationsTableProps {
  applications: (IApplicationViewModel | null)[] | null,
  onClick: (application: IApplicationViewModel) => void;
  currency: string;
  sortingType: ApplicationsSortingType;
  onSort: (field: ApplicationSortingField, ascending: boolean) => void;
  isLoading?: boolean;
}

const ApplicationsTable: FC<IApplicationsTableProps> = ({
  applications,
  onClick,
  currency,
  sortingType,
  onSort,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderTableBody = () => {
    if (!applications || applications.some((item) => !item) || isLoading) {
      return <SkeletonApplicationsTable />;
    }

    if (!isLoading && applications && applications.length === 0) {
      return <EmptyTable text={t('home.noApplications')} />;
    }

    return applications.map(
      (application, index) => (
        <TableRow
          key={application!.id}
          onClick={() => onClick(application!)}
          hasTopBorder={!index}
          className={styles.tableRowContainer}
        >
          <ApplicationsTableRow application={application!} key={application!.id} currency={currency} />
        </TableRow>
      ),
    );
  };

  return (
    <>
      <TableHead className={styles.tableHead}>
        <TableHeadCell width={ApplicationsTableColumnSizes.ProductName}>
          {t('home.productName')}
        </TableHeadCell>
        <TableHeadCell
          width={ApplicationsTableColumnSizes.Id}
          ascending={applications && applications.length ? getSortingType(ApplicationSortingField.DisplayId) : undefined}
          onClick={applications && applications.length ? () => changeSorting(ApplicationSortingField.DisplayId) : undefined}
        >
          {t('home.applicationId')}
        </TableHeadCell>
        <TableHeadCell
          width={ApplicationsTableColumnSizes.LoanAmount}
          ascending={applications && applications.length ? getSortingType(ApplicationSortingField.LoanAmount) : undefined}
          onClick={applications && applications.length ? () => changeSorting(ApplicationSortingField.LoanAmount) : undefined}
        >
          {t('home.loanAmount')}
        </TableHeadCell>
        <TableHeadCell width={ApplicationsTableColumnSizes.Status}>
          {t('home.status')}
        </TableHeadCell>
        <TableHeadCell
          width={ApplicationsTableColumnSizes.ApplicationDate}
          ascending={applications && applications.length ? getSortingType(ApplicationSortingField.CreatedAt) : undefined}
          onClick={applications && applications.length ? () => changeSorting(ApplicationSortingField.CreatedAt) : undefined}
        >
          {t('common.applicationDate')}
        </TableHeadCell>
      </TableHead>
      <TableBody>{renderTableBody()}</TableBody>
    </>
  );
};

export default ApplicationsTable;
