import React, { FC, forwardRef, ReactNode, Ref } from 'react';
import styles from './ActionPopUp.module.scss';
import { CloseImage } from 'product_modules/static/images';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export interface ActionPopUpProps {
  title?: string;
  onClose?: () => void;
  children: ReactNode;
  hideTitle?: boolean;
  className?: string;
}

const ActionPopUp: FC<ActionPopUpProps> = forwardRef(
  ({ onClose, children, title, hideTitle, className }, ref: Ref<HTMLDivElement>) => {
    const { t } = useTranslation()

    return (
      <div className={clsx(styles.actionsPopUp, className)} ref={ref}>
        {!hideTitle && (
          <div className={styles.actionsPopUp__header}>
            <p>{title || t('common.actions')}</p>
            <button type="button" onClick={onClose}>
              <CloseImage />
            </button>
          </div>
        )}
        <div className={styles.actionsPopUp__body}>{children}</div>
      </div>
    );
  },
);

export default ActionPopUp;
