import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FilePreview from 'components/common/FilePreview';
import { applicationDocumentsApi } from 'handlers/documentsSlice';
import { DocumentExtension } from 'api/digifi/DocumentsApi';
import MainLayout from 'layout/MainLayout';
import styles from './FilePreviewPage.module.scss';

const FilePreviewPage: FC = () => {
  const { fileExtension, fileId, filename } = useParams<{
    fileExtension: DocumentExtension;
    fileId: string;
    filename: string;
  }>();
  const [previewLink, setPreviewLink] = useState<string>('');

  useEffect(() => {
    if (!fileId) {
      return;
    }

    (async () => {
      const link = await applicationDocumentsApi.getPublicLink(fileId);
      setPreviewLink(link);
    })();
  }, []);

  return (
    <MainLayout innerContentClassName={styles.innerContent}>
      <FilePreview
        filename={filename || ''}
        downloadFileContent={(id) => applicationDocumentsApi.download(id)}
        fileExtension={fileExtension || DocumentExtension.Pdf}
        fileId={fileId || ''}
        previewLink={previewLink || ''}
      />
    </MainLayout>
  );
};

export default FilePreviewPage;
