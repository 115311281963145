import React, { FC, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import VerificationForm, { IVerificationFormContextType } from 'components/Auth/forms/VerificationForm/VerificationForm';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getCurrentAccount, logout, sendPhoneVerificationCode, verifyPhone } from 'handlers/authSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { createNotification } from 'handlers/notificationsSlice';
import { AppRoute } from 'enums/AppRoute';
import { formatPhoneNumber } from 'product_modules/components/PhoneInput';
import { RequestError } from 'errors/RequestError';
import { HttpStatusCode } from 'enums/HttpStatusCode';
import createPathWithBackUrl from 'utils/redirectWithBackUrl';
import useBackUrlQueryParam from 'hooks/useBackUrlQueryParam';
import FormTitle from 'components/PageLayout/FormTitle';
import AuthFormContainer from 'components/Auth/AuthFormContainer';
import { resetDisplayIdToRedirect } from 'handlers/applicationDataSlice';
import { ErrorCode } from 'types/ErrorCode';
import styles from './PhoneVerification.module.scss';

const PhoneVerification: FC = () => {
  const { t } = useTranslation();
  const { accountData } = useAppSelector((state) => state.auth);
  const { displayIdToRedirect } = useAppSelector((state) => state.applicationData);
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const backUrl = useBackUrlQueryParam();

  useEffect(() => {
    dispatch(sendPhoneVerificationCode());
  }, []);

  const sendCode = () => {
    dispatch(sendPhoneVerificationCode());
  };

  const handleSubmit = async (values: IVerificationFormContextType) => {
    try {
      setIsLoading(true);
      await dispatchWithUnwrap(verifyPhone(values.code));
      await dispatchWithUnwrap(getCurrentAccount());
      setIsLoading(false);

      const route = displayIdToRedirect
        ? `${AppRoute.Application}/${displayIdToRedirect}`
        : backUrl || AppRoute.Home;

      dispatch(resetDisplayIdToRedirect());

      navigate(route);
    } catch (error) {
      const requestError = error as RequestError;
      setIsLoading(false);

      if (requestError.responseStatus === HttpStatusCode.BadRequest && requestError.code !== ErrorCode.MfaCodeVerificationFailed) {
        dispatch(logout());
        navigate(createPathWithBackUrl(AppRoute.SignIn, backUrl));
      }

      createNotification({ type: 'error', notification: requestError.message, dispatch });
    }
  };

  const renderSubtitle = () => {
    return (
      <div>
        <Trans i18nKey="phoneAuthVerification.enterPhoneVerificationCode">
          Please enter the code sent to <span className={styles.maskedPhone}>{{phone: accountData?.phone}}</span>.
        </Trans>
      </div>
    );
  };

  const formSubtitle = accountData?.isPhoneVerified
    ? renderSubtitle()
    : t('common.enterVerificationCode', {target: formatPhoneNumber(accountData?.phone || '')});

  return (
    <UnauthorizedLayout
      footerLinkText={t('common.backToSignIn')}
      footerLink={AppRoute.SignIn}
      logoutOnLinkClick
    >
      <AuthFormContainer>
        <FormTitle noMargin title={accountData?.isPhoneVerified ? t('phoneAuthVerification.phoneAuthentication') : t('phoneAuthVerification.phoneVerification')} subTitle={formSubtitle} />
        <Formik initialValues={{ code: '' }} onSubmit={handleSubmit}>
          <VerificationForm buttonTitle={t('common.verifyPhone')} resendCode={sendCode} isLoading={isLoading} />
        </Formik>
      </AuthFormContainer>
    </UnauthorizedLayout>
  );
};

export default PhoneVerification;
