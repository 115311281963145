import { VariableValue } from 'product_modules/api/Types';
import { IdentificationTypeVisualAttributes, VariableVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import { IdentificationType } from 'product_modules/components/IdentificationNumberInput/types';
import { CorrespondenceChecker } from 'product_modules/utils/valueVisualTypeCorrespondenceCheckers/Types';

const MAX_OTHER_TYPE_VALUE_LENGTH = 100;
const NUMBERS_ONLY_REGEXP = /^\d+$/;

const isCorrespondsIdentificationType: CorrespondenceChecker<string> = (
  value: VariableValue,
  attributes?: VariableVisualAttributes,
): value is string => {
  if (typeof value !== 'string') {
    return false;
  }

  if ((attributes as IdentificationTypeVisualAttributes)?.identificationNumberType === IdentificationType.Other) {
    return value.length <= MAX_OTHER_TYPE_VALUE_LENGTH;
  }

  return NUMBERS_ONLY_REGEXP.test(value);
};

export default isCorrespondsIdentificationType;
