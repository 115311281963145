import React, { FC, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PopUp from 'components/digifi-wrappers/PopUp';
import PopUpContent from 'components/digifi-wrappers/PopUpContent';
import ProvidePhoneNumberStep from 'components/BorrowerProfile/LoginDetails/ChangePhonePopup/Steps/ProvidePhoneNumberStep';
import VerifyPhoneStep from 'components/BorrowerProfile/LoginDetails/ChangePhonePopup/Steps/VerifyPhoneStep';
import { createNotification } from 'handlers/notificationsSlice';
import ConfirmPasswordStep from 'components/BorrowerProfile/LoginDetails/BasePopupSteps/ConfirmPasswordStep';
import { setAccountData } from 'handlers/authSlice';
import TokenUtils from 'utils/TokenUtils';
import { formatPhoneNumber } from 'product_modules/components/PhoneInput';
import { RequestError } from 'errors/RequestError';
import { HttpStatusCode } from 'enums/HttpStatusCode';
import styles from './ChangePhonePopup.module.scss';

enum ChangePhonePopupStep {
  ConfirmPassword = 'ConfirmPasswordStep',
  VerifyOldPhone = 'VerifyOldPhoneStep',
  ProvidePhone = 'ProvidePhoneStep',
  VerifyNewPhone = 'VerifyNewPhoneStep',
}

interface IChangePhonePopupProps {
  onConfirmPassword: (password: string) => Promise<void>;
  onSendOldPhoneCode: () => Promise<void>;
  onSendCode: (phone: string) => Promise<void>;
  onVerifyOldPhoneCode: (code: string) => Promise<void>;
  onVerifyNewPhoneCode: (code: string) => Promise<void>;
  onClose: () => void;
  oldPhone: string;
  phoneNumberFormat?: string;
}

const ChangePhonePopup: FC<IChangePhonePopupProps> = ({
  phoneNumberFormat,
  onConfirmPassword,
  onSendOldPhoneCode,
  onSendCode,
  onVerifyOldPhoneCode,
  onVerifyNewPhoneCode,
  onClose,
  oldPhone,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(
    TokenUtils.isPasswordValidationTokenValid()
      ? ChangePhonePopupStep.VerifyOldPhone
      : ChangePhonePopupStep.ConfirmPassword,
  );
  const [newPhone, setNewPhone] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (step === ChangePhonePopupStep.VerifyOldPhone) {
      handleOldPhoneResendCode();
    }
  }, []);

  const handleConfirmPassword = async (password: string) => {
    try {
      setIsLoading(true);
      await onConfirmPassword(password);
      setIsLoading(false);
    } catch (error) {
      createNotification({
        notification: t('toastMessages.passwordIsInvalid'),
        type: 'error',
        dispatch,
      });
    }

    try {
      setStep(ChangePhonePopupStep.VerifyOldPhone);
      await onSendOldPhoneCode();
    } catch (error) {
      createNotification({
        notification: error.message,
        type: 'error',
        dispatch,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOldPhoneCode = async (code: string) => {
    try {
      setIsLoading(true);
      await onVerifyOldPhoneCode(code);
      setStep(ChangePhonePopupStep.ProvidePhone);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnSendCode = async (phone: string) => {
    try {
      setIsLoading(true);
      await onSendCode(phone);

      batch(() => {
        setNewPhone(phone);
        setStep(ChangePhonePopupStep.VerifyNewPhone);
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyNewPhoneCode = async (code: string) => {
    try {
      setIsLoading(true);
      await onVerifyNewPhoneCode(code);

      createNotification({
        notification: t('common.enterVerificationCode', { target: formatPhoneNumber(newPhone || '') }),
        type: 'success',
        dispatch,
      });

      dispatch(setAccountData({ phone: newPhone || '' }));
      onClose();
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewPhoneResendCode = async () => {
    try {
      if (!newPhone) {
        return;
      }

      await onSendCode(newPhone);
      createNotification({
        notification: t('toastMessages.newCodeSent', { target: formatPhoneNumber(newPhone) }),
        type: 'success',
        dispatch,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleOldPhoneResendCode = async () => {
    await onSendOldPhoneCode();
    createNotification({
      notification: t('toastMessages.newCodeSent', { target: formatPhoneNumber(oldPhone) }),
      type: 'success',
      dispatch,
    });
  };

  const handleError = (error: RequestError) => {
    createNotification({
      notification: error.message,
      type: 'error',
      dispatch,
    });

    if (error.responseStatus === HttpStatusCode.Forbidden) {
      setStep(ChangePhonePopupStep.ConfirmPassword);
    }
  };

  return (
    <PopUp
      title={t('loginDetails.phoneSetupVerification')}
      closePopUp={onClose}
      classNames={{
        sectionClassName: styles.popUpContainer,
        header: styles.popUpHeader,
      }}
    >
      <PopUpContent className={styles.popUpContent}>
        {step === ChangePhonePopupStep.ConfirmPassword && (
          <ConfirmPasswordStep onContinue={handleConfirmPassword} isLoading={isLoading} />
        )}
        {step === ChangePhonePopupStep.VerifyOldPhone && (
          <VerifyPhoneStep
            onResendCode={handleOldPhoneResendCode}
            onSubmit={handleVerifyOldPhoneCode}
            isLoading={isLoading}
            message={t('common.verifyPhoneIdentity', { target: formatPhoneNumber(oldPhone) })}
            buttonKind='secondary'
          />
        )}
        {step === ChangePhonePopupStep.ProvidePhone && (
          <ProvidePhoneNumberStep
            initialPhone={newPhone || ''}
            phoneNumberFormat={phoneNumberFormat}
            onContinue={handleOnSendCode}
            isLoading={isLoading}
          />
        )}
        {step === ChangePhonePopupStep.VerifyNewPhone && (
          <VerifyPhoneStep
            onResendCode={handleNewPhoneResendCode}
            onSubmit={handleVerifyNewPhoneCode}
            message={t('common.enterVerificationCode', { target: formatPhoneNumber(newPhone || '') })}
            isLoading={isLoading}
          />
        )}
      </PopUpContent>
    </PopUp>
  );
};

export default ChangePhonePopup;
