import React, { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { OopsImage } from 'static/images';
import styles from './PageNoAvailable.module.scss';

interface IPageNoAvailableProps {
  className?: string;
}

const PageNoAvailable: FC<IPageNoAvailableProps> = ({
  className,
}) => {
  const { t } = useTranslation();
  
  return (
    <div className={clsx(styles.container, className)}>
      <OopsImage className={styles.image} />
      <div className={styles.title}>{t('pageBuilderElements.pageNotAvailable')}</div>
    </div>
  );
};

export default PageNoAvailable;
