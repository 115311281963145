import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { nanoid } from 'nanoid';
import { IReduxState } from 'types/ReduxState';
import { EntitiesLoader } from 'product_modules/providers/createEntitiesLoaderProvider';

interface ICreateUseEntitiesByHookParams<Entity, Field extends string | number> {
  mapSelector: (state: IReduxState) => Partial<Record<string, Entity>>;
  mapUnknownFields: (state: IReduxState) => Partial<Record<string, boolean>>;
  useLoader: () => EntitiesLoader<Field> | null;
}

export interface IIUseEntitiesByHookOptions {
  returnPartialResult?: boolean;
}

const createUseEntitiesByHook = <Entity, Field extends string | number>({
  mapSelector,
  mapUnknownFields,
  useLoader,
}: ICreateUseEntitiesByHookParams<Entity, Field>) => {
  const DEFAULT_FIELDS: Field[] = [];

  return (fields: Field[] | null | undefined, options?: IIUseEntitiesByHookOptions) => {
    const requestId = useMemo(() => nanoid(), [fields]);

    const dataSelector = useMemo(() => {
      return createSelector(
        mapSelector,
        mapUnknownFields,
        (state: IReduxState, fieldsInSelector: Field[] | null | undefined) => fieldsInSelector ?? DEFAULT_FIELDS,
        (entitiesMap, unknownFields, fieldsInSelector) => {
          return { entitiesMap, unknownFields, fields: fieldsInSelector };
        },
      );
    }, []);

    const entitiesSelector = useMemo(() => {
      return createSelector(
        dataSelector,
        (data) => {
          return data.fields.reduce((result, field) => {
            const entity = data.entitiesMap[field.toString()];

            if (entity) {
              result.entities[field.toString()] = entity;

              return result;
            }

            if (!data.unknownFields[field.toString()]) {
              result.missedFields.push(field);
            }

            return result;
          }, {
            missedFields: [] as Field[],
            entities: {} as Record<string, Entity>,
          });
        },
        {
          memoizeOptions: {
            equalityCheck: (currentData, previousData) => {
              if (currentData === previousData) {
                return true;
              }

              if (currentData.fields !== previousData.fields) {
                return false;
              }

              return currentData.fields.every((field: Field) => {
                const isFieldUnknown = currentData.unknownFields[field.toString()];
                const isPreviousFieldUnknown = previousData.unknownFields[field.toString()];

                const currentEntity = currentData.entitiesMap[field.toString()] ?? (isFieldUnknown ? null : undefined);
                const previousEntity = previousData.entitiesMap[field.toString()] ?? (isPreviousFieldUnknown ? null : undefined);

                return currentEntity === previousEntity;
              });
            },
          },
        },
      );
    }, []);

    const { entities, missedFields } = useSelector((state: IReduxState) => {
      return entitiesSelector(state, fields);
    });

    const loader = useLoader();

    useEffect(() => {
      if (missedFields.length) {
        loader?.load(missedFields, requestId);
      }

      return () => {
        loader?.abort(requestId);
      };
    }, [fields, loader]);

    return missedFields.length && !options?.returnPartialResult ? null : entities;
  };
};

export default createUseEntitiesByHook;
