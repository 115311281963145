import { FC, useMemo } from 'react';
import { TableValue } from 'product_modules/api/Types';
import {
  IButtonElementConfig,
  IESignatureElement,
  IOfferSelectionPageElement,
} from 'api/digifi/portal-page-elements';
import { TaskStatus } from 'api/digifi/TasksApi';
import { useAppSelector } from 'hooks/reduxHooks';
import { createGetTasksSelector } from 'handlers/tasks/Selectors';
import { useGetDocuSignIntegrationDataQuery } from 'slices';
import ButtonElement from './ButtonElement';
import { getFormContinueButtonDisabledTooltip } from 'constants/FormContinueButtonDisabledTooltip';
import useApplicationStatusUpdatePolling from './useApplicationStatusUpdatePolling';

interface IButtonElementConnectorProps {
  config: IButtonElementConfig;
  onSubmit: () => Promise<void>;
  offersVariableValue: TableValue | null;
  offersSelectionElement: IOfferSelectionPageElement | null;
  eSignatureElement: IESignatureElement | null;
  applicationDisplayId: string;
  containerClassName?: string;
  isSubmitInProgress?: boolean;
}

const ButtonElementConnector: FC<IButtonElementConnectorProps> = ({
  config,
  onSubmit,
  containerClassName,
  isSubmitInProgress,
  offersVariableValue,
  offersSelectionElement,
  eSignatureElement,
  applicationDisplayId,
}) => {
  const taskIds = useAppSelector((state) => state.applicationData.taskIds);
  const isValidConfiguration = useAppSelector((state) => state.layoutConfigurations.isValidConfiguration);

  const getTasksSelector = useMemo(() => {
    return createGetTasksSelector(taskIds);
  }, [taskIds]);

  const tasks = useAppSelector(getTasksSelector);

  const {
    currentData: docuSignIntegrationData,
    isLoading: isDocuSignIntegrationDataLoading,
    isFetching: isDocuSignIntegrationDataFetching,
  } = useGetDocuSignIntegrationDataQuery(applicationDisplayId, {
    skip: !eSignatureElement,
  });

  const { isPlaidConnected, isPlaidElementExists } = useAppSelector((state) => state.plaid);

  const isESignatureElementIncomplete = !!eSignatureElement && (
    isDocuSignIntegrationDataLoading
    || isDocuSignIntegrationDataFetching
    || !!docuSignIntegrationData?.embeddedSigningUrl
    || !!docuSignIntegrationData?.waitingForOthers
  );

  const buttonDisabled = Object.values(isValidConfiguration).some((isValid) => !isValid)
    || tasks?.some((task) => task.status !== TaskStatus.InReview)
    || (!!offersVariableValue && offersVariableValue.every((offer) => !offer[offersSelectionElement?.config.selectedColumn || '']))
    || isESignatureElementIncomplete
    || isPlaidElementExists && !isPlaidConnected;

  useApplicationStatusUpdatePolling();

  return (
    <ButtonElement
      config={config}
      containerClassName={containerClassName}
      onSubmit={onSubmit}
      disabled={buttonDisabled}
      isLoading={isSubmitInProgress}
      tooltip={buttonDisabled && getFormContinueButtonDisabledTooltip()}
    />
  );
};

export default ButtonElementConnector;
