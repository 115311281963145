import { useTranslation } from 'react-i18next';
import { IOnboardingData } from 'hooks/useOnboardingFlow';
import CreateAccountForm from 'components/Auth/forms/CreateAccountForm';
import { ICreateAccountFormParams } from 'types';
import AuthFormContainer from 'components/Auth/AuthFormContainer';
import FormTitle from 'components/PageLayout/FormTitle';

interface ISubmitStepProps {
  isSubmitInProgress: boolean;
  onSubmit: (params: ICreateAccountFormParams) => Promise<void>;
  onStepBack: () => void;
  onboardingData: IOnboardingData;
}

const SubmitStep = ({
  isSubmitInProgress,
  onStepBack,
  onboardingData,
  onSubmit,
}: ISubmitStepProps) => {
  const { t } = useTranslation();
  
  return (
    <AuthFormContainer>
      <FormTitle
        title={t('createAccount.createAccount')}
        subTitle={t('createAccount.createAccountPrompt')}
      />
      <CreateAccountForm
        onBack={onStepBack}
        isLoading={isSubmitInProgress}
        handleSubmit={onSubmit}
        initialData={{
          email: onboardingData?.borrowerFormData?.borrower_email as string | undefined,
          phoneNumber: onboardingData?.borrowerFormData?.borrower_phone as string | undefined,
        }}
        allowEditInitialData
      />
    </AuthFormContainer>
  );
};

export default SubmitStep;
