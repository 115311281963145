import React, { useState, FC, useEffect, FocusEvent } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core';
import DatePickerInput, { InputSize } from './Input';
import { LoaderState } from 'product_modules/components/LoaderWithState/LoaderWithState';
import clsx from 'clsx';
import TranslationService from 'product_modules/services/TranslationService';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import getDefaultDateFormat from 'product_modules/utils/getDefaultDateFormat';
import formatDateValue from 'product_modules/utils/valueFormatters/formatDateValue';
import formatDate, { DateTimeFormat } from 'product_modules/utils/dateFormat';
import useUtilsClass from './useUtilsClass';
import DateTimeUtils from 'product_modules/utils/dateUtils';
import { DEFAULT_DATE_FORMAT } from 'product_modules/utils/valueFormatters/base/constants';
import DatePickerLocaleLoader from './DatePickerLocaleLoader';
import styles from './DatePickerWrapper.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: styles.palettePrimaryMain,
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        borderRadius: '6px',
        transition: '0.25s color ease-in-out, 0.25s background-color ease-in-out',
        '&:hover': {
          backgroundColor: styles.hoverColor,
          color: styles.palettePrimaryMain,
        },
      },
    },
    MuiTypography: {
      body1: {
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#000000',
      },
      caption: {
        fontWeight: 'bold',
        fontSize: '9px',
        lineHeight: '12px',
        letterSpacing: '0.1em',
        color: styles.captionColor,
      },
      body2: {
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
      },
      subtitle1: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 'bold',
      },
      h4: {
        fontSize: '28px',
        lineHeight: '36px',
        fontWeight: 'bold',
      },
    },
    // @ts-ignore
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: styles.toolbarTextColor,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: styles.dayOfWeekColor,
      },
    },
    MuiPickersDay: {
      day: {
        color: styles.dayNumberColor,
      },
      current: {
        border: `2px solid ${styles.palettePrimaryMain}`,
        color: styles.palettePrimaryMain,
        '&:hover': {
          border: `2px solid ${styles.palettePrimaryMain}`,
          color: styles.palettePrimaryMain,
          backgroundColor: styles.currentDay,
        },
      },
      daySelected: {
        '&:hover': {
          color: styles.currentDay,
        },
      },
    },
  },
  typography: {
    fontFamily: styles.fontFamily,
  },
});

interface DatePickerProps {
  value: string;
  labelTitle?: string;
  onChange: (value: string) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  disabled?: boolean;
  dateFormat?: string;
  placeholder?: string;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  inputClassName?: string;
  inputSize?: InputSize;
  minDate?: Date;
  maxDate?: Date;
  required?: boolean;
  errorMessage?: string;
  inputIcon?: React.ReactNode;
  tooltip?: string;
  showErrorMessage?: () => void;
  hideErrorMessage?: () => void;
  nullable?: boolean;
  calendarInputOverlayClassName?: string;
  onInputChange?: (value: string | null) => void;
  useSimplifiedInput?: boolean;
  style?: React.CSSProperties;
  titleHint?: string;
  labelTooltipClassName?: string;
}

const DatePickerWrapper: FC<DatePickerProps> = ({
  value,
  labelTitle,
  readOnly,
  disabled,
  onChange,
  dateFormat = DEFAULT_DATE_FORMAT,
  onFocus,
  onBlur,
  placeholder = dateFormat,
  showLoader,
  loaderState,
  onLoaderStateReset,
  tabIndex,
  inputSize = 'form',
  inputClassName,
  minDate,
  maxDate,
  required,
  errorMessage,
  tooltip,
  inputIcon,
  showErrorMessage,
  hideErrorMessage,
  calendarInputOverlayClassName,
  onInputChange,
  nullable = true,
  useSimplifiedInput,
  style,
  titleHint,
  labelTooltipClassName,
}) => {
  const UtilsClass = useUtilsClass(dateFormat);

  const isValidCurrentDateFormat = (dateString: string) => {
    const date = DateTimeUtils.parse(dateString, dateFormat);
    return date.isValid();
  };

  const isValidDefaultDateFormat = (dateString: string) => {
    const date = DateTimeUtils.parse(dateString, DateTimeFormat.UsShortWithSlashes);
    return date.isValid();
  };

  const formatInputValue = (dateString: string) => {
    if (!dateString) {
      return null;
    }

    return isValidDefaultDateFormat(dateString)
      ? formatDateValue(dateString, { dateFormat })
      : dateString;
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [inputValue, setInputValue] = useState(formatInputValue(value));

  const onCalendarIconClick = () => {
    setIsCalendarOpen(!disabled && !isCalendarOpen);

    if (!disabled) {
      hideErrorMessage?.();
    }
  };

  const getCorrectedDate = (val: string) => {
    if (minDate && new Date(val) < minDate) {
      return formatDate(minDate, dateFormat);
    }

    if (maxDate && new Date(val) > maxDate) {
      return formatDate(maxDate, dateFormat);
    }

    return val;
  };

  const handleChange = (date: MaterialUiPickersDate, newValue?: string | null) => {
    if (readOnly) {
      return;
    }
    if (!newValue && nullable) {
      onChange('');

      return;
    }

    if (newValue && isValidCurrentDateFormat(newValue)) {
      const correctedValue = getCorrectedDate(newValue);

      if (correctedValue !== newValue) {
        setInputValue(formatInputValue(correctedValue));
        onInputChange?.(formatInputValue(correctedValue));
      }

      onChange(getDefaultDateFormat(correctedValue, dateFormat));

      return;
    }

    setInputValue(formatInputValue(newValue || ''));
    onInputChange?.(formatInputValue(newValue || ''));
  };

  const handleClose = () => {
    setIsCalendarOpen(false);

    showErrorMessage?.();
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (!nullable) {
      setInputValue(value);
      onInputChange?.(value);
    }

    onBlur?.(event);
  };

  useEffect(() => {
    setInputValue(formatInputValue(value));
    onInputChange?.(formatInputValue(value));
  }, [value]);

  const TextFieldComponent = DatePickerInput as React.ComponentType<TextFieldProps>;
  const textFieldProps = {
    showLoader,
    loaderState,
    onLoaderStateReset,
    onIconClick: onCalendarIconClick,
    tabIndex,
    inputClassName,
    inputSize,
    required,
    errorMessage,
    inputIcon,
    tooltip,
    calendarInputOverlayClassName,
    useSimplifiedInput,
    style,
    titleHint,
    labelTooltipClassName,
  };

  const locale = TranslationService.getCurrentLocale();

  return (
    <ThemeProvider theme={theme}>
      <DatePickerLocaleLoader locale={locale} />
      <MuiPickersUtilsProvider utils={UtilsClass} locale={locale}>
        <div className={clsx(
          styles.datePickerContainer,
          useSimplifiedInput && styles.datePickerContainerSimplified,
          !labelTitle && styles.noLabel,
        )}>
          <KeyboardDatePicker
            TextFieldComponent={TextFieldComponent}
            open={isCalendarOpen}
            onClose={handleClose}
            format={dateFormat}
            label={labelTitle}
            value={inputValue}
            inputValue={inputValue || undefined}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={handleOnBlur}
            autoOk
            variant="inline"
            placeholder={placeholder}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            readOnly={readOnly}
            disabled={disabled}
            {...textFieldProps}
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DatePickerWrapper;
