import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import ApplicationOverview from 'components/ApplicationDetails/ApplicationOverview';
import { ApplicationDocumentsSortingField, ApplicationDocumentsSortingType, IApplicationDocument } from 'api/digifi/DocumentsApi';
import DocumentsTable from 'components/ApplicationDetails/DocumentsTable';
import { sortApplicationDocuments } from 'utils/sortFiles';
import styles from './ApplicationDetails.module.scss';

interface IApplicationDetailsProps {
  application: IApplicationViewModel | null;
  documents: IApplicationDocument[] | null;
  onAllDocumentsDownload: () => void;
  onDocumentDownload: (document: IApplicationDocument) => void;
  onOpenDocument: (document: IApplicationDocument) => void;
}

const ApplicationDetails: FC<IApplicationDetailsProps> = ({
  application,
  documents,
  onAllDocumentsDownload,
  onDocumentDownload,
  onOpenDocument,
}) => {
  const { t } = useTranslation();

  const [sortingType, setSortingType] = useState<ApplicationDocumentsSortingType>({
    ascending: false,
    field: ApplicationDocumentsSortingField.CreatedAt,
  });

  const sortedDocument = documents && sortApplicationDocuments(documents, sortingType);

  return (
    <div className={styles.modalContent}>
      <ApplicationOverview
        application={application}
        isLoading={!sortedDocument}
      />
      <div className={styles.documentsSection}>
        <div className={styles.header}>{t('applicationDetails.applicationDocuments')}</div>
        <div className={styles.documentsContainer}>
          <DocumentsTable
            documents={sortedDocument}
            onAllDocumentsDownloadClick={onAllDocumentsDownload}
            onDocumentDownloadClick={onDocumentDownload}
            onDocumentClick={onOpenDocument}
            sortingType={sortingType}
            onSort={(field, ascending) => setSortingType({
              field,
              ascending,
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default ApplicationDetails;
