export default class DispatchWithUnwrapError<OriginalError = unknown> extends Error {
  public name = 'DispatchWithUnwrapError';

  constructor(
    message: string,
    public readonly originalErrorName: string,
    public readonly originalError: OriginalError,
  ) {
    super(`[${originalErrorName}] ${message}`);
  }
}
