import { FC, useEffect, useState } from 'react';
import { getSettings } from 'handlers/settingsSlice';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getCurrentAccount } from 'handlers/authSlice';
import { getAllAvailableProducts } from 'handlers/productsSlice';
import { useAppSelector } from 'hooks/reduxHooks';
import TranslationService from 'product_modules/services/TranslationService';

const WorkflowWrapper: FC = ({ children }) => {
  const [isSettingsLoading, setIsSettingsLoading] = useState<boolean>(true);
  const [isTranslationsLoading, setIsTranslationsLoading] = useState<boolean>(true);
  const branding = useAppSelector((state) => state.settings.branding);

  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const fetchTranslations = async () => {
    if (branding.language) {
      try {
        setIsTranslationsLoading(true);

        TranslationService.setLanguage(branding.language);
        await TranslationService.loadTranslationResources(branding.language);
      } finally {
        setIsTranslationsLoading(false);
      }
    }
  };

  const fetchSettings = async () => {
    try {
      setIsSettingsLoading(true);

      await Promise.allSettled([
        dispatchWithUnwrap(getSettings()),
        dispatchWithUnwrap(getCurrentAccount()),
        dispatchWithUnwrap(getAllAvailableProducts()),
      ]);
    } finally {
      setIsSettingsLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    fetchTranslations();
  }, [branding]);

  const isLoading = isTranslationsLoading || isSettingsLoading;

  return <>{isLoading ? <LoaderOverlay /> : children}</>;
};

export default WorkflowWrapper;
