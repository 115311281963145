import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/reduxHooks';
import CreateApplicationForm from 'components/ApplicationForm/CreateApplicationForm';
import { VariableValue } from 'product_modules/api/Types';
import { getExistingBorrowerData, updateBorrower } from 'handlers/borrowersSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { createApplication } from 'handlers/applicationsSlice';
import { IProduct } from 'api/digifi/ProductsApi';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import useMultipleConfigurableFormData from 'components/ApplicationForm/CreateApplication/useMultipleConfigurableFormData';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { replaceAt } from 'product_modules/utils/arrayUtils';
import { setAllowToDiscardNewApplication, setCurrentApplication } from 'handlers/activeBorrowerInformationSlice';
import useAsyncActionCallback from 'product_modules/hooks/useAsyncActionCallback';
import { IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import { AppRoute } from 'enums/AppRoute';
import useFakeLoader from 'hooks/useFakeLoader';
import useCreateApplicationFlow from 'hooks/useCreateApplicationFlow';

interface ICreateApplicationProps {
  product: IProduct;
  coborrowersCount: number;
}

const MIN_LOADING_TIME = 5000;

const CreateApplication: FC<ICreateApplicationProps> = ({ product, coborrowersCount }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState<boolean>(false);
  const [createdApplication, setCreatedApplication] = useState<IApplicationViewModel | null>(null);
  const [createApplicationData, setCreateApplicationData, clearOnboardingData, stepsNavigation] =
    useCreateApplicationFlow(product);

  const [
    multipleCoBorrowersFormData,
    handleMultipleCoBorrowersDataChange,
    handleMultipleCoBorrowersFieldBlur,
    resetCoBorrowerFormData,
  ] = useMultipleConfigurableFormData({
    count: coborrowersCount,
    initialMultipleData: createApplicationData.coBorrowerFormData,
  });

  useEffect(() => {
    setCreateApplicationData((prevValue) => ({
      ...prevValue,
      coBorrowerFormData: multipleCoBorrowersFormData,
    }));
  }, [multipleCoBorrowersFormData]);

  useEffect(() => {
    dispatch(getExistingBorrowerData());
  }, []);

  const handleBorrowerProfileFieldChange = useCallback((variable: Variable, value: VariableValue) => {
    setCreateApplicationData((previousBorrowerFormData) => ({
      ...previousBorrowerFormData,
      borrowerFormData: { ...previousBorrowerFormData.borrowerFormData, [variable.systemName]: value },
    }));
  }, []);

  const [isSubmitInProgress, handleCreateApplication] = useAsyncActionCallback(async () => {
    await dispatchWithUnwrap(updateBorrower({ variables: createApplicationData.borrowerFormData }));

    if (!product) {
      return;
    }

    const application = await dispatchWithUnwrap(
      createApplication({
        productId: product.id,
        coborrowers: multipleCoBorrowersFormData.map((variables, index) => ({
          type: createApplicationData.coBorrowerTypes[index],
          variables,
        })),
      }),
    );

    setCreatedApplication(application);
    dispatch(setCurrentApplication(application.id));
  }, [product, createApplicationData, multipleCoBorrowersFormData]);

  const handleSubmit = async () => {
    setIsSubmitButtonClicked(true);
    dispatch(setAllowToDiscardNewApplication(false));
    await handleCreateApplication();
  };

  const handleCoBorrowerTypeChange = (type: BorrowerType, index: number) => {
    setCreateApplicationData((previousBorrowerFormData) => ({
      ...previousBorrowerFormData,
      coBorrowerTypes: replaceAt(previousBorrowerFormData.coBorrowerTypes, index, type),
    }));
    resetCoBorrowerFormData(index);
  };

  const handleCreateApplicationFinish = () => {
    if (createdApplication) {
      clearOnboardingData();
      navigate(`${AppRoute.Application}/${createdApplication?.displayId}`);
    }
  };

  const isShowingFakeLoader = useFakeLoader({
    shouldLoadingStart: isSubmitButtonClicked,
    minLoadingTime: MIN_LOADING_TIME,
  });

  useEffect(() => {
    if (!isSubmitInProgress && !isShowingFakeLoader) {
      handleCreateApplicationFinish();
    }
  }, [isShowingFakeLoader, isSubmitButtonClicked, isSubmitInProgress, handleCreateApplicationFinish]);

  return (
    <CreateApplicationForm
      borrowerType={createApplicationData.borrowerType}
      coBorrowersTypes={createApplicationData.coBorrowerTypes}
      product={product}
      coborrowersCount={coborrowersCount}
      stepsNavigation={stepsNavigation}
      handleCoBorrowerTypeChange={handleCoBorrowerTypeChange}
      handleUpdateBorrowerFormData={handleBorrowerProfileFieldChange}
      borrowerFormData={createApplicationData.borrowerFormData}
      handleMultipleCoBorrowersDataChange={handleMultipleCoBorrowersDataChange}
      handleMultipleCoBorrowersFieldBlur={handleMultipleCoBorrowersFieldBlur}
      multipleCoBorrowersFormData={multipleCoBorrowersFormData}
      handleSubmit={handleSubmit}
      isLoading={isShowingFakeLoader}
    />
  );
};

export default CreateApplication;
