import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { FC } from 'react';
import { AppRoute } from 'enums/AppRoute';
import styles from 'components/Notifications/NotificationList/customNotifications/AlreadyHaveAnAccountErrorNotification/AlreadyHaveAnAccountErrorNotification.module.scss';

const AlreadyHaveAnAccountErrorNotification: FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(AppRoute.SignIn);
  };

  return (
    <div>
      <Trans i18nKey="toastMessages.accountAlreadyExists">
        It looks like you already have an account. Please{' '}
        <span className={styles.link} onClick={handleClick}>
          sign in.
        </span>
      </Trans>
    </div>
  );
};

export default AlreadyHaveAnAccountErrorNotification;
