import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITask, TaskStatus } from 'api/digifi/TasksApi';
import { ISubmitTaskParams } from 'api/digifi/TaskDetailsApi';
import InstructionsSection from 'components/PortalPageElements/TasksElement/sections/InstructionsSection';
import UploadFile from 'components/common/UploadFile';
import acceptFileTypes from 'utils/acceptFileTypes';
import TextArea from 'components/digifi-wrappers/TextArea';
import ButtonWithLoadingState from 'components/digifi-wrappers/ButtonWithLoadingState';
import { DEFAULT_MAX_FILE_SIZE } from 'utils/defaultFileSize';
import styles from './EditTaskPopup.module.scss';

interface IEditTaskForm {
  task: ITask;
  onSubmit: (params: ISubmitTaskParams) => Promise<void>;
  loading?: boolean;
}

const MAX_FILES_COUNT = 5;
const MAX_COMMENT_INPUT_ROWS_COUNT = 5;

const EditTaskForm: FC<IEditTaskForm> = ({
  task,
  onSubmit,
  loading,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[] | null>(null);
  const [comment, setComment] = useState<string>('');

  const handleFileChange = (newFile: File | null, index?: number) => {
    setFiles((prevState) => {
      const updatedFiles = prevState ? [...prevState] : [];

      if (newFile) {
        return [...updatedFiles, newFile];
      }

      return typeof index !== 'undefined'
        ? updatedFiles.filter((_file, fileIndex) => index !== fileIndex)
        : updatedFiles;
    });
  };

  const handleCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const isFormValid = () => {
    return files && files.length > 0 && files.length <= MAX_FILES_COUNT;
  };

  const handleSubmit = async () => {
    if (!isFormValid()) {
      return;
    }

    await onSubmit({
      applicationId: task.applicationId,
      taskId: task.id,
      taskStatus: TaskStatus.InReview,
      ...(files ? { files } : {}),
      ...(comment ? { comment } : {}),
    });
  };

  const renderUploadFileTooltip = () => {
    return (
      <>
        <p className={styles.tooltipLine}>
          {t('tooltips.acceptedFormats')}<br/>
          {t('tooltips.acceptedSize')}
        </p>
      </>
    );
  };

  return (
    <>
      <InstructionsSection task={task} />
      <UploadFile
        inputLabel={t('pageBuilderElements.uploadFiles')}
        maxFileSize={DEFAULT_MAX_FILE_SIZE}
        accept={acceptFileTypes}
        files={files}
        onFileChange={handleFileChange}
        className={styles.uploadFileContainer}
        fileDeleteContainerClassName={styles.fileDeleteContainer}
        dropDisabled={!!files?.length && files.length >= MAX_FILES_COUNT}
        required
        tooltip={renderUploadFileTooltip()}
        tooltipClassName={styles.tooltip}
      />
      <TextArea
        labelTitle={t('pageBuilderElements.comments')}
        value={comment}
        onChange={handleCommentChange}
        multiline
        minRows={MAX_COMMENT_INPUT_ROWS_COUNT}
        maxRows={MAX_COMMENT_INPUT_ROWS_COUNT}
        disabled={loading}
      />
      <ButtonWithLoadingState
        size="form"
        kind="primary"
        offset="form"
        onClick={handleSubmit}
        disabled={!isFormValid()}
        className={styles.submitButton}
      >
        {t('pageBuilderElements.sendForReview')}
      </ButtonWithLoadingState>
    </>
  );
};

export default EditTaskForm;
