import { FocusEvent } from 'react';
import { IBaseVariableConfiguration } from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import { VariableValue } from 'product_modules/api/Types';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { BorrowerInformationStep, CoBorrowerInformationStep } from './steps';
import CreateApplicationStep from 'enums/CreateApplicationStep';
import { IProduct } from 'api/digifi/ProductsApi';
import { StepValue } from 'hooks/useStepsNavigation';
import styles from './CreateApplicationForm.module.scss';

type CoborrowerInformationOnboardingStep =
  | CreateApplicationStep.CoBorrower
  | CreateApplicationStep.CoBorrower2
  | CreateApplicationStep.CoBorrower3;

const COBORROWER_INFORMATION_STEPS = [
  CreateApplicationStep.CoBorrower,
  CreateApplicationStep.CoBorrower2,
  CreateApplicationStep.CoBorrower3,
];

interface ICreateApplicationFormProps {
  borrowerType: BorrowerType;
  coBorrowersTypes: BorrowerType[];
  product: IProduct;
  coborrowersCount: number;
  stepsNavigation: {
    currentStep: StepValue<typeof CreateApplicationStep>;
    onStepForward: () => void;
    onStepBack: () => void;
  };
  handleUpdateBorrowerFormData: (variable: Variable, value: VariableValue) => void;
  handleCoBorrowerTypeChange: (type: BorrowerType, index: number) => void;
  multipleCoBorrowersFormData: Record<string, VariableValue>[];
  handleMultipleCoBorrowersDataChange: (
    index: number,
    field: IBaseVariableConfiguration,
    variable: Variable,
    value: VariableValue,
  ) => boolean;
  handleMultipleCoBorrowersFieldBlur: (
    index: number,
    field: IBaseVariableConfiguration,
    variable: Variable,
    event?: FocusEvent<HTMLInputElement, Element> | undefined,
  ) => void;
  borrowerFormData: Record<string, VariableValue>;
  handleSubmit: () => void;
  isLoading: boolean;
}

const CreateApplicationForm = ({
  borrowerType,
  coBorrowersTypes,
  product,
  coborrowersCount,
  stepsNavigation,
  handleCoBorrowerTypeChange,
  handleUpdateBorrowerFormData,
  handleMultipleCoBorrowersDataChange,
  multipleCoBorrowersFormData,
  handleMultipleCoBorrowersFieldBlur,
  borrowerFormData,
  handleSubmit,
  isLoading,
}: ICreateApplicationFormProps) => {
  const { currentStep, onStepForward } = stepsNavigation;
  const coborrowersSteps = COBORROWER_INFORMATION_STEPS.slice(0, coborrowersCount);

  const handleStepForward = () => {
    if (coborrowersSteps.length === 0 && currentStep === CreateApplicationStep.Borrower) {
      handleSubmit();

      return;
    }

    if (coborrowersSteps[coborrowersSteps.length - 1] === currentStep) {
      handleSubmit();

      return;
    }

    onStepForward();
  };

  const renderCoBorrowerInformationStep = (step: CoborrowerInformationOnboardingStep) => {
    const index = COBORROWER_INFORMATION_STEPS.indexOf(step);

    if (!product) {
      return;
    }

    return (
      <CoBorrowerInformationStep
        key={index}
        index={index}
        isLoading={isLoading}
        coBorrowersTypes={coBorrowersTypes}
        availableBorrowerTypes={product.borrowerTypes}
        coBorrowersFormData={multipleCoBorrowersFormData}
        onChangeCoBorrowerType={(type) => handleCoBorrowerTypeChange(type, index)}
        onCoBorrowerFieldBlur={(...attributes) => handleMultipleCoBorrowersFieldBlur(index, ...attributes)}
        onCoBorrowerFieldChange={(...attributes) => handleMultipleCoBorrowersDataChange(index, ...attributes)}
        {...stepsNavigation}
        onStepForward={handleStepForward}
      />
    );
  };

  const renderForm = () => {
    return (
      <>
        {currentStep === CreateApplicationStep.Borrower && product && (
          <BorrowerInformationStep
            isLoading={isLoading}
            borrowerType={borrowerType}
            borrowerFormData={borrowerFormData}
            availableBorrowerTypes={product.borrowerTypes}
            coBorrowersFormData={multipleCoBorrowersFormData}
            onSetBorrowerFormData={handleUpdateBorrowerFormData}
            {...stepsNavigation}
            onStepForward={handleStepForward}
          />
        )}
        {coborrowersSteps.includes(currentStep) &&
          renderCoBorrowerInformationStep(currentStep as CoborrowerInformationOnboardingStep)}
      </>
    );
  };

  return <div className={styles.formWrapper}>{renderForm()}</div>;
};

export default CreateApplicationForm;
