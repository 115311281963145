import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import clsx from 'clsx';
import { IProduct } from 'api/digifi/ProductsApi';
import ContainerStyled from 'components/BrandingHelpers/ContainerStyled';
import PRODUCT_IMAGE_BY_TYPE from 'components/ApplicationForm/ProductListModal/ProductList/ProductImageByType';
import styles from './ProductList.module.scss';

interface IProductListProps {
  products: IProduct[];
  selectedProductId: string;
  className?: string;
  onSelect: (id: string) => void;
}

const ProductList: FC<IProductListProps> = ({ products, selectedProductId, className, onSelect }) => {
  const { t } = useTranslation();

  const renderProductContent = (product: IProduct) => {
    const Icon = PRODUCT_IMAGE_BY_TYPE[product.type];

    return (
      <>
        <div className={styles.productName}>{product.name}</div>
        <Icon className={styles.productIcon} />
      </>
    );
  };

  const renderProduct = (product: IProduct) => {
    if (product.id === selectedProductId) {
      return (
        <ContainerStyled key={product.id} className={clsx(styles.product, styles.active)}>
          {renderProductContent(product)}
        </ContainerStyled>
      );
    }

    return (
      <div className={styles.product} onClick={() => onSelect(product.id)} key={product.id}>
        {renderProductContent(product)}
      </div>
    );
  };

  return (
    <div className={className}>
      <div className={styles.header}>{t('startNewApp.selectProduct')}</div>
      <div>{products.map(renderProduct)}</div>
    </div>
  );
};

export default ProductList;
