import { useTranslation } from 'react-i18next';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { BorrowerTypeCompanyIcon, BorrowerTypePersonIcon } from 'static/images';
import OptionCard from 'components/Auth/Onboarding/components/OptionCard/OptionCard';
import StepWrapper, { IStepNavigationProps } from 'components/common/StepWrapper';
import styles from './BorrowerTypeStep.module.scss';

interface IBorrowerTypeStepProps extends IStepNavigationProps {
  borrowerType: BorrowerType | null;
  onSetBorrowerType: (borrowerType: BorrowerType) => void;
}

const BorrowerTypeStep = ({
  borrowerType,
  onSetBorrowerType,
  ...restProps
}: IBorrowerTypeStepProps) => {
  const { t } = useTranslation();

  const renderBorrowerTypeCard = (type: BorrowerType) => {
    const isSelected = type === borrowerType;
    const isPerson = type === BorrowerType.Person;

    return (
      <OptionCard
        key={type}
        icon={isPerson ? <BorrowerTypePersonIcon /> : <BorrowerTypeCompanyIcon />}
        text={isPerson ? t('common.person') : t('common.company')}
        isSelected={isSelected}
        onClick={() => onSetBorrowerType(type)}
        className={styles.cardContent}
      />
    );
  };

  return (
    <StepWrapper
      title={t('questionPages.applicantTypeQuestion')}
      subtitle={t('questionPages.selectApplicantType')}
      disableContinue={!borrowerType}
      {...restProps}
    >
      {Object.values(BorrowerType).map(renderBorrowerTypeCard)}
    </StepWrapper>
  );
};

export default BorrowerTypeStep;
