import { FC, lazy, Suspense } from 'react';
import 'dayjs/locale/es';
import { BrandingLanguage } from 'api/digifi/SettingsApi';

const DaLocale = lazy(async () => import('./locales/DaLocale'));
const DeLocale = lazy(async () => import('./locales/DeLocale'));
const EnLocale = lazy(async () => import('./locales/EnLocale'));
const EsLocale = lazy(async () => import('./locales/EsLocale'));
const FiLocale = lazy(async () => import('./locales/FiLocale'));
const FrLocale = lazy(async () => import('./locales/FrLocale'));
const HuLocale = lazy(async () => import('./locales/HuLocale'));
const ItLocale = lazy(async () => import('./locales/ItLocale'));
const NbLocale = lazy(async () => import('./locales/NbLocale'));
const NlLocale = lazy(async () => import('./locales/NlLocale'));
const PtLocale = lazy(async () => import('./locales/PtLocale'));
const RoLocale = lazy(async () => import('./locales/RoLocale'));
const RuLocale = lazy(async () => import('./locales/RuLocale'));
const SvLocale = lazy(async () => import('./locales/SvLocale'));
const TrLocale = lazy(async () => import('./locales/TrLocale'));

const LOCALE_BY_LANGUAGE = {
  [BrandingLanguage.DA]: DaLocale,
  [BrandingLanguage.DE]: DeLocale,
  [BrandingLanguage.EN]: EnLocale,
  [BrandingLanguage.ES]: EsLocale,
  [BrandingLanguage.FI]: FiLocale,
  [BrandingLanguage.FR]: FrLocale,
  [BrandingLanguage.HU]: HuLocale,
  [BrandingLanguage.IT]: ItLocale,
  [BrandingLanguage.NB]: NbLocale,
  [BrandingLanguage.NL]: NlLocale,
  [BrandingLanguage.PT]: PtLocale,
  [BrandingLanguage.RO]: RoLocale,
  [BrandingLanguage.RU]: RuLocale,
  [BrandingLanguage.SV]: SvLocale,
  [BrandingLanguage.TR]: TrLocale,
};

export interface IDatePickerLocaleLoaderProps {
  locale: BrandingLanguage;
}

const DatePickerLocaleLoader: FC<IDatePickerLocaleLoaderProps> = ({ locale }) => {
  const Locale = LOCALE_BY_LANGUAGE[locale];

  return (
    <Suspense fallback={null}>
      <Locale />
    </Suspense>
  );
};

export default DatePickerLocaleLoader;
