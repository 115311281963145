import clsx from 'clsx';
import { forwardRef } from 'react';
import { PopUp, PopUpProps } from 'product_modules/components/PopUp';
import styles from './PopUp.module.scss';

const PopUpWrapper = forwardRef<HTMLElement, PopUpProps>(({ classNames, ...props }, ref) => {
  return (
    <PopUp
      {...props}
      ref={ref}
      classNames={{
        ...classNames,
        closeIcon: clsx(styles.closeIcon, classNames?.closeIcon),
        header: clsx(styles.header, classNames?.header),
        title: clsx(styles.title, classNames?.title),
      }}
      usePortal
    />
  );
});

export default PopUpWrapper;
