import formatAddressValue from 'product_modules/utils/valueFormatters/formatAddressValue';
import { VariableValue } from 'product_modules/api/Types';
import { VariableDataType } from 'product_modules/api/Core/VariablesApi';
import DateTimeUtils from 'product_modules/utils/dateUtils';
import { DateTimeFormat } from 'product_modules/utils/dateFormat';
import { isArray } from 'lodash';

export const isDate = (value: unknown): value is Date => {
  return typeof value === 'object' && value instanceof Date;
};

export const convertStringToBoolean = (value: string): boolean | null => {
  switch (value.toLowerCase().trim()) {
    case 'true': {
      return true;
    }
    case 'false': {
      return false;
    }
    default: {
      return null;
    }
  }
};

export const convertStringToNumber = (value: string): number | null => {
  const numberValue = Number(value);

  return Number.isNaN(numberValue) ? null : numberValue;
};

const convertToBooleanDataType = (variableValue: unknown) => {
  switch (typeof variableValue) {
    case 'boolean': {
      return variableValue;
    }
    case 'string': {
      return convertStringToBoolean(variableValue);
    }
    case 'number': {
      return Boolean(variableValue);
    }
    case 'object': {
      return true;
    }
    default: {
      return null;
    }
  }
};

const convertToNumberDataType = (variableValue: unknown) => {
  switch (typeof variableValue) {
    case 'boolean': {
      return Number(variableValue);
    }
    case 'string': {
      return convertStringToNumber(variableValue);
    }
    case 'number': {
      return isFinite(variableValue) ? variableValue : null;
    }
    default: {
      return null;
    }
  }
};

const convertToStringDataType = (variableValue: unknown) => {
  switch (typeof variableValue) {
    case 'boolean':
    case 'number':
    case 'string': {
      return variableValue.toString();
    }
    case 'object': {
      return variableValue ? formatAddressValue(variableValue) : '';
    }
    default: {
      return null;
    }
  }
};

const convertToDateDataType = (variableValue: unknown) => {
  if (typeof variableValue === 'boolean') {
    return variableValue;
  }

  const date = DateTimeUtils.parseUnsafe(variableValue as Date | string);

  return date.isValid()
    ? date.format(DateTimeFormat.UsShortWithSlashes)
    : variableValue;
};

const convertToAddressDataType = (variableValue: unknown) => {
  if (typeof variableValue !== 'object' || variableValue?.constructor.name !== 'Object') {
    return null;
  }

  return variableValue;
};

const convertToTableDataType = (variableValue: unknown) => {
  if (!Array.isArray(variableValue) || variableValue.some((row) => typeof row !== 'object')) {
    return null;
  }

  return variableValue;
};

type ConvertReturnType<Type extends VariableDataType> =
  Type extends VariableDataType.Date ? VariableValue :
  Type extends VariableDataType.Address ? object | null :
  Type extends VariableDataType.Boolean? boolean | null :
  Type extends VariableDataType.Number? number | null :
  Type extends VariableDataType.String? string | null :
  Type extends VariableDataType.Table ? object | null :
  null;

function convertVariableValueByDataType<Type extends VariableDataType>(
  variableValue: VariableValue,
  dataType: Type,
): ConvertReturnType<Type> | null {
  if (variableValue === null || variableValue === undefined || variableValue === '') {
    return null;
  }

  switch (dataType) {
    case VariableDataType.Boolean: {
      return convertToBooleanDataType(variableValue) as ConvertReturnType<Type>;
    }
    case VariableDataType.Number: {
      return convertToNumberDataType(variableValue) as ConvertReturnType<Type>;
    }
    case VariableDataType.Date: {
      return convertToDateDataType(variableValue) as ConvertReturnType<Type>;
    }
    case VariableDataType.String: {
      return convertToStringDataType(variableValue) as ConvertReturnType<Type>;
    }
    case VariableDataType.Address: {
      return convertToAddressDataType(variableValue) as ConvertReturnType<Type>;
    }
    case VariableDataType.Table: {
      return convertToTableDataType(variableValue) as ConvertReturnType<Type>;
    }
    default: {
      return null;
    }
  }
}

export { convertVariableValueByDataType };

export const convertUnknownValueToString = (value: unknown, placeholderEmptyValue?: boolean): string => {
  if (value === null) {
    return placeholderEmptyValue ? 'null' : '';
  }

  if (value === undefined) {
    return placeholderEmptyValue ? 'undefined' : '';
  }

  switch (typeof value) {
    case 'boolean':
    case 'number':
    case 'string': {
      return value.toString();
    }
    case 'object': {
      if (Array.isArray(value)) {
        return value.join('; ');
      }

      if (isDate(value)) {
        return value.toISOString();
      }

      return JSON.stringify(value);
    }
    default: {
      return '';
    }
  }
};

export const convertVariableValueToString = (
  variableValue: VariableValue,
  placeholderEmptyValue?: boolean,
): string => {
  if (variableValue !== null && typeof variableValue === 'object' && !isArray(variableValue)) {
    return formatAddressValue(variableValue);
  }

  return convertUnknownValueToString(variableValue, placeholderEmptyValue);
};

