import React, { FC, useState, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import accepts from 'attr-accept';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PptIcon, XlsIcon, DocIcon, JsonIcon, ImageIcon, PdfIcon } from 'product_modules/static/images';
import { DocumentExtension } from 'product_modules/api/LoanOriginationSystem/DocumentsApi';
import Label from 'product_modules/components/Label';
import UploadedDocumentStub from 'components/common/UploadedDocumentStub';
import LinkButton from 'components/digifi-wrappers/LinkButton';
import styles from './UploadFile.module.scss';

export const iconsByExtension = new Map([
  [DocumentExtension.Xls, <XlsIcon />],
  [DocumentExtension.Xlsx, <XlsIcon />],
  [DocumentExtension.Csv, <XlsIcon />],
  [DocumentExtension.Doc, <DocIcon />],
  [DocumentExtension.Docx, <DocIcon />],
  [DocumentExtension.Xml, <DocIcon />],
  [DocumentExtension.Txt, <DocIcon />],
  [DocumentExtension.Ppt, <PptIcon />],
  [DocumentExtension.Pptx, <PptIcon />],
  [DocumentExtension.Pdf, <PdfIcon />],
  [DocumentExtension.Json, <JsonIcon />],
  [DocumentExtension.Gif, <ImageIcon />],
  [DocumentExtension.Png, <ImageIcon />],
  [DocumentExtension.Jpeg, <ImageIcon />],
  [DocumentExtension.Jpg, <ImageIcon />],
  [DocumentExtension.Svg, <ImageIcon />],
  [DocumentExtension.Ico, <ImageIcon />],
  [DocumentExtension.Heic, <ImageIcon />],
  [DocumentExtension.Webp, <ImageIcon />],
]);

export interface IUploadFromFileProps {
  file?: File | null;
  files?: File[] | null;
  onFileChange: (file: File | null, index?: number) => void;
  accept?: string | string[];
  maxFileSize?: number;
  text?: ReactNode;
  closeIcon?: React.ReactNode;
  inputLabel?: string;
  className?: string;
  fileDeleteContainerClassName?: string;
  dropDisabled?: boolean;
  required?: boolean;
  tooltip?: React.ReactNode;
  tooltipClassName?: string;
}

const validateFileSize = (fileSize: number, maxFileSize?: number) => {
  if (typeof maxFileSize === 'undefined') {
    return true;
  }

  return fileSize <= maxFileSize;
};

const INPUT_ID = 'fileInput';

const UploadFile: FC<IUploadFromFileProps> = ({
  file,
  files,
  onFileChange,
  accept,
  maxFileSize,
  text,
  closeIcon,
  inputLabel,
  className,
  dropDisabled,
  required,
  fileDeleteContainerClassName,
  tooltip,
  tooltipClassName,
}) => {
  const { t } = useTranslation();
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validateFile = (newFile: File, index?: number) => {
    const isAcceptable = !accept || accepts(newFile, accept);

    if (!isAcceptable) {
      setErrorMessage(t('validationErrors.fileFormatNotSupported'));
      return;
    }

    if (!validateFileSize(newFile.size, maxFileSize)) {
      setErrorMessage(t('validationErrors.maxFileSizeExceeded'));
      return;
    }

    setErrorMessage(null);
    onFileChange(newFile, index);
  };

  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    const newFile = event.target!.files![0];
    validateFile(newFile, index);
  };

  const handleFileDrag = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const newFile = e.dataTransfer.files[0];
    validateFile(newFile);
    setIsDragOver(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setIsDragOver(false);
  };

  const labelClassName = clsx(
    isDragOver ? styles.dragOverInputWrapper : styles.inputWrapper,
    errorMessage && styles.errorInputWrapper,
  );

  const renderUploadedFileInfo = (uploadedFile: File, index?: number) => (
    <UploadedDocumentStub
      key={nanoid()}
      fileName={uploadedFile.name}
      onFileChange={onFileChange}
      closeIcon={closeIcon}
      index={index}
      fileDeleteContainerClassName={fileDeleteContainerClassName}
    />
  );

  const renderDropZone = () => (
    <>
      <label
        className={clsx(labelClassName, dropDisabled && styles.disabled)}
        onDrop={handleFileDrag}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <p className={styles.textContainer}>
          {text || (
            <Trans i18nKey='pageBuilderElements.dragFileToUpload'>
              Drag a file here or{' '}
              <LinkButton tag='span'>browse</LinkButton> for a file to upload.
            </Trans>
          )}
        </p>
        <input
          type='file'
          value=''
          id={INPUT_ID}
          className={styles.fileInput}
          onChange={handleUploadFile}
          accept={isArray(accept) ? accept.join(',') : accept}
        />
      </label>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </>
  );

  return (
    <div className={className}>
      {inputLabel ? (
        <Label htmlFor={INPUT_ID} required={required} tooltip={tooltip} tooltipClassName={tooltipClassName}>
          {inputLabel}
        </Label>
      ) : null}
      <div className={styles.dropZoneContainer}>{file ? renderUploadedFileInfo(file) : renderDropZone()}</div>
      <div className={styles.multipleFilesContainer}>
        {files?.map((uploadedFile, index) => renderUploadedFileInfo(uploadedFile, index))}
      </div>
    </div>
  );
};

export default UploadFile;
