import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { useAppSelector } from 'hooks/reduxHooks';
import OptionCard from 'components/Auth/Onboarding/components/OptionCard/OptionCard';
import PRODUCT_IMAGE_BY_TYPE from 'components/ApplicationForm/ProductListModal/ProductList/ProductImageByType';
import { IProduct } from 'api/digifi/ProductsApi';
import StepWrapper, { IStepNavigationProps } from 'components/common/StepWrapper';

interface IProductStepProps extends IStepNavigationProps {
  borrowerType: BorrowerType;
  productId: string | null;
  onSetProduct: (product: IProduct) => void;
}

const ProductStep = ({
  borrowerType,
  productId,
  onSetProduct,
  ...restProps
}: IProductStepProps) => {
  const { t } = useTranslation();
  const { entities: productEntities } = useAppSelector((state) => state.products);

  const productIdsToRender = useMemo(() => {
    return Object.values(productEntities).reduce((result, product) => {
      if (!product) {
        return result;
      }

      if (product.borrowerTypes.includes(borrowerType)) {
        result.push(product.id);
      }

      return result;
    }, [] as string[]);
  }, [productEntities, borrowerType]);

  const renderProductCard = (id: string) => {
    const product = productEntities[id];

    if (!product) {
      return null;
    }

    const isSelected = productId === id;

    const Icon = PRODUCT_IMAGE_BY_TYPE[product.type];

    return (
      <OptionCard
        key={id}
        onClick={() => onSetProduct(product)}
        isSelected={isSelected}
        icon={<Icon />}
        text={product.name}
      />
    );
  };

  return (
    <StepWrapper
      title={t('questionPages.whatAreYouLookingFor')}
      subtitle={t('questionPages.selectProductPrompt')}
      disableContinue={!productId}
      {...restProps}
    >
      {productIdsToRender.map(renderProductCard)}
    </StepWrapper>
  );
};

export default ProductStep;
