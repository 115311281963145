import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonWithLoadingState from 'components/digifi-wrappers/ButtonWithLoadingState';
import PasswordInput from 'components/digifi-wrappers/PasswordInput';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';
import styles from './ConfirmPasswordStep.module.scss';

interface IConfirmPasswordStepProps {
  onContinue: (password: string) => Promise<void>;
  isLoading?: boolean;
}

const ConfirmPasswordStep: FC<IConfirmPasswordStepProps> = ({ onContinue, isLoading }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isPasswordValid = password.length >= 1;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <div>
      <div className={styles.message}>{t('loginDetails.confirmIdentityPrompt')}</div>
      <PasswordInput
        placeholder={t('loginDetails.enterPassword')}
        labelTitle={t('loginDetails.yourPassword')}
        value={password}
        required
        onChange={handleOnChange}
        onKeyDown={TriggerEventOnEnterKeyDown(buttonRef, 'click')}
        disabled={isLoading}
      />
      <ButtonWithLoadingState
        ref={buttonRef}
        className={styles.button}
        disabled={!isPasswordValid}
        size="form"
        onClick={() => onContinue(password)}
      >
        {t('common.continue')}
      </ButtonWithLoadingState>
    </div>
  );
};

export default ConfirmPasswordStep;
