import { useCallback, useEffect } from 'react';
import useOnboardingFlow from 'hooks/useOnboardingFlow';
import OnboardingStep from 'enums/OnboardingStep';
import {
  BorrowerInformationStep,
  BorrowerTypeStep,
  CoBorrowerInformationStep,
  CoborrowersStep,
  ProductStep,
  SubmitStep,
} from 'components/Auth/Onboarding/steps';
import useMultipleConfigurableFormData
  from 'components/ApplicationForm/CreateApplication/useMultipleConfigurableFormData';
import { VariableValue } from 'product_modules/api/Types';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { replaceAt } from 'product_modules/utils/arrayUtils';

type CoborrowerInformationOnboardingStep =
  | OnboardingStep.CoBorrower
  | OnboardingStep.CoBorrower2
  | OnboardingStep.CoBorrower3;

const COBORROWER_INFORMATION_STEPS = [
  OnboardingStep.CoBorrower,
  OnboardingStep.CoBorrower2,
  OnboardingStep.CoBorrower3,
];

const Onboarding = () => {
  const [
    onboardingData,
    setOnboardingData,
    clearOnboardingData,
    stepsNavigation,
  ] = useOnboardingFlow();

  const { currentStep } = stepsNavigation;

  const [
    multipleCoBorrowersFormData,
    handleMultipleCoBorrowersDataChange,
    handleMultipleCoBorrowersFieldBlur,
    resetCoBorrowerFormData,
    removeCoBorrowerFormData,
  ] = useMultipleConfigurableFormData({
    count: onboardingData.coBorrowerTypes.length,
    initialMultipleData: onboardingData.coBorrowerFormData,
  });

  useEffect(() => {
    setOnboardingData((prevValue) => ({
      ...prevValue,
      coBorrowerFormData: multipleCoBorrowersFormData,
    }));
  }, [multipleCoBorrowersFormData]);

  const handleCoBorrowerTypeChange = useCallback((type: BorrowerType, index: number) => {
    setOnboardingData((prevState) => ({
      ...prevState,
      coBorrowerTypes: replaceAt(prevState.coBorrowerTypes, index, type),
    }));
    resetCoBorrowerFormData(index);
  }, [resetCoBorrowerFormData]);

  const handleUpdateBorrowerFormData = useCallback((variable: Variable, value: VariableValue) => {
    setOnboardingData((previousState) => ({
      ...previousState,
      borrowerFormData: { ...previousState.borrowerFormData, [variable.systemName]: value },
    }));
  }, []);

  const handleUpdateCoborrowerTypes = useCallback((coBorrowerTypes: BorrowerType[], deletedCoborrowerIndex?: number) => {
    setOnboardingData((prevState) => ({
      ...prevState,
      coBorrowerTypes,
    }));

    if (deletedCoborrowerIndex === undefined) {
      return;
    }

    removeCoBorrowerFormData(deletedCoborrowerIndex);
  }, [removeCoBorrowerFormData]);

  const renderCoBorrowerInformationStep = (step: CoborrowerInformationOnboardingStep) => {
    const index = COBORROWER_INFORMATION_STEPS.indexOf(step);
    const { product } = onboardingData;

    if (!product) {
      return;
    }

    return (
      <CoBorrowerInformationStep
        key={index}
        index={index}
        availableBorrowerTypes={product.borrowerTypes}
        coBorrowersTypes={onboardingData.coBorrowerTypes}
        coBorrowersFormData={multipleCoBorrowersFormData}
        onCoBorrowerFieldChange={(...attributes) => handleMultipleCoBorrowersDataChange(index, ...attributes)}
        onChangeCoBorrowerType={(type) => handleCoBorrowerTypeChange(type, index)}
        onCoBorrowerFieldBlur={(...attributes) => handleMultipleCoBorrowersFieldBlur(index, ...attributes)}
        {...stepsNavigation}
      />
    );
  };

  return (
    <div>
      {currentStep === OnboardingStep.BorrowerType && (
        <BorrowerTypeStep
          borrowerType={onboardingData.borrowerType || null}
          onSetBorrowerType={(type) => setOnboardingData((prevState) => ({ ...prevState, borrowerType: type, product: null }))}
          {...stepsNavigation}
        />
      )}
      {currentStep === OnboardingStep.Product && onboardingData.borrowerType && (
        <ProductStep
          borrowerType={onboardingData.borrowerType}
          productId={onboardingData.product?.id || null}
          onSetProduct={(product) => setOnboardingData((prevState) => ({ ...prevState, product }))}
          {...stepsNavigation}
        />
      )}
      {currentStep === OnboardingStep.Coborrowers && onboardingData.product && (
        <CoborrowersStep
          product={onboardingData.product}
          coborrowerTypes={onboardingData.coBorrowerTypes}
          onSetCoborrowerTypes={handleUpdateCoborrowerTypes}
          {...stepsNavigation}
        />
      )}
      {currentStep === OnboardingStep.Borrower && onboardingData.borrowerType && onboardingData.product && (
        <BorrowerInformationStep
          borrowerFormData={onboardingData.borrowerFormData}
          borrowerType={onboardingData.borrowerType}
          onSetBorrowerFormData={handleUpdateBorrowerFormData}
          coBorrowersFormData={multipleCoBorrowersFormData}
          {...stepsNavigation}
        />
      )}
      {COBORROWER_INFORMATION_STEPS.includes(currentStep) && renderCoBorrowerInformationStep(currentStep as CoborrowerInformationOnboardingStep)}
      {currentStep === OnboardingStep.Submit && (
        <SubmitStep
          onboardingData={onboardingData}
          clearOnboardingData={clearOnboardingData}
          onStepBack={stepsNavigation.onStepBack}
        />
      )}
    </div>
  );
};

export default Onboarding;
