import { FocusEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BorrowerProfileForm from 'components/ApplicationForm/forms/BorrowerProfileForm';
import { VariableValue } from 'product_modules/api/Types';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import { IBaseVariableConfiguration } from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import StepWrapper, { IStepNavigationProps } from 'components/common/StepWrapper';
import styles from './CoBorrowerInformationStep.module.scss';

interface ICoBorrowerInformationStepProps extends IStepNavigationProps {
  index: number;
  availableBorrowerTypes: Array<BorrowerType>;
  coBorrowersFormData: Array<Record<string, VariableValue>>;
  coBorrowersTypes: Array<BorrowerType>;
  onChangeCoBorrowerType: (type: BorrowerType) => void;
  onCoBorrowerFieldChange: (field: IBaseVariableConfiguration, variable: Variable, value: VariableValue) => void;
  onCoBorrowerFieldBlur: (field: IBaseVariableConfiguration, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
}

const CoBorrowerInformationStep = ({
  index,
  availableBorrowerTypes,
  coBorrowersFormData,
  coBorrowersTypes,
  onChangeCoBorrowerType,
  onCoBorrowerFieldChange,
  onCoBorrowerFieldBlur,
  ...restProps
}: ICoBorrowerInformationStepProps) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);

  return (
    <StepWrapper
      title={
        index
          ? t('borrowerProfile.coApplicantInformationIndex', { index: index + 1 })
          : t('borrowerProfile.coApplicantInformation')
      }
      subtitle={t('borrowerProfile.coApplicantPrompt')}
      disableContinue={!isValid}
      contentClassName={styles.content}
      {...restProps}
    >
      <BorrowerProfileForm
        borrowerFormData={coBorrowersFormData[index]}
        coBorrowersFormData={coBorrowersFormData}
        onFieldChange={onCoBorrowerFieldChange}
        onFieldBlur={onCoBorrowerFieldBlur}
        selectedBorrowerType={coBorrowersTypes[index]}
        availableBorrowerTypes={availableBorrowerTypes}
        onChangeBorrowerType={onChangeCoBorrowerType}
        onFormValidationChange={setIsValid}
        borrowerTypeToggleClassName={styles.borrowerTypeToggle}
      />
    </StepWrapper>
  );
};

export default CoBorrowerInformationStep;
