import { FC } from 'react';
import clsx from 'clsx';
import styles from 'components/PageLayout/FormTitle/FormTitle.module.scss';

interface IFormTitleProps {
  title: string;
  subTitle?: string | JSX.Element;
  noMargin?: boolean;
}

const FormTitle: FC<IFormTitleProps> = ({ title, subTitle, noMargin }) => {
  return (
    <>
      <div className={clsx(styles.formTitle, noMargin && styles.noMargin)}>{title}</div>
      {subTitle && <div className={styles.formSubtitle}>{subTitle}</div>}
    </>
  );
};

export default FormTitle;
