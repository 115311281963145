import { FieldComponent } from 'product_modules/api/Types';
import { StringVisualDataType } from 'product_modules/enums/VisualDataType';
import TranslationService from 'product_modules/services/TranslationService';
import formatAddressValue from '../utils/valueFormatters/formatAddressValue';

export const getAddressComponents: () => FieldComponent[] = () => [
  {
    id: 'street_number',
    label: TranslationService.translate('otherComponents.streetNumber')    ,
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'street_name',
    label: TranslationService.translate('otherComponents.streetName'),
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'unit_number',
    label: TranslationService.translate('otherComponents.unitNumber'),
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'city',
    label: TranslationService.translate('otherComponents.city'),
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'sublocality',
    label: TranslationService.translate('otherComponents.sublocality'),
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'state_or_province',
    label: TranslationService.translate('otherComponents.stateOfProvince'),
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'zip_or_postal_code',
    label: TranslationService.translate('otherComponents.postalCode'),
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  }, {
    id: 'country',
    label: TranslationService.translate('otherComponents.country'),
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
  },
  {
    id: 'full_address',
    label: TranslationService.translate('otherComponents.fullAddress'),
    visualDataType: StringVisualDataType.Text,
    visualAttributes: {},
    virtual: true,
    transform: (value) => {
      return typeof value === 'object' && !!value ? formatAddressValue(value) : '';
    },
    toFormula: (variable: string) => {
      return `BUILDFULLADDRESS(${variable})`;
    },
  }
];
