import { useDispatch } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { AsyncThunkAction, unwrapResult } from '@reduxjs/toolkit';
import DispatchWithUnwrapError from 'errors/DispatchWithUnwrapError';

interface IUnwrappableAction {
  payload: any;
  meta?: any;
  error?: any;
}

interface IAsyncThunkConfig {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
}

const useDispatchWithUnwrap = () => {
  const dispatch = useDispatch();

  return async <Returned, ThunkArg, ThunkApiConfig extends IAsyncThunkConfig>(
    action: AsyncThunkAction<Returned, ThunkArg, ThunkApiConfig>,
  ) => {
    try {
      const result = await dispatch(action as unknown as AnyAction);

      return unwrapResult((result as unknown) as IUnwrappableAction) as Returned;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }

      throw new DispatchWithUnwrapError(error.message, error.name, error);
    }
  };
};

export default useDispatchWithUnwrap;
