import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationFormPageBehavior, ApplicationFormPageType, IProduct } from 'api/digifi/ProductsApi';
import OptionCard from 'components/Auth/Onboarding/components/OptionCard';
import { CoborrowerIcon, PlusIcon } from 'static/images';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import StepWrapper, { IStepNavigationProps } from 'components/common/StepWrapper';
import styles from './CoborrowersStep.module.scss';

interface ICoborrowersStepProps extends IStepNavigationProps {
  product: IProduct;
  coborrowerTypes: BorrowerType[];
  onSetCoborrowerTypes: (coBorrowerTypes: BorrowerType[], deletedCoborrowerIndex?: number) => void;
}

const coborrowerKeys = [
  ApplicationFormPageType.CoBorrower,
  ApplicationFormPageType.CoBorrower2,
  ApplicationFormPageType.CoBorrower3,
];

const CoborrowersStep = ({
  product,
  coborrowerTypes,
  onSetCoborrowerTypes,
  ...restProps
}: ICoborrowersStepProps) => {
  const { t } = useTranslation();
  const borrowerTypeToSet = product.borrowerTypes[0];
  const formPages = product.applicationFormPages;

  const shouldRenderAddCoborrower = useMemo(() => {
    return coborrowerKeys.some((key, index) => formPages[key] === ApplicationFormPageBehavior.Optional && !coborrowerTypes[index]);
  }, [coborrowerTypes]);

  const renderCoborrower = (_coborrowerType: BorrowerType, index: number) => {
    const formPageType = coborrowerKeys[index];
    const behavior = formPages[formPageType];

    const handleRemoveCoborrower = () => {
      if (!coborrowerTypes) {
        return;
      }

      onSetCoborrowerTypes(coborrowerTypes.filter((_, i) => index !== i), index);
    };

    return (
      <OptionCard
        key={index}
        isSelected
        text={`${t('common.coApplicant')} ${index ? index + 1 : ''}`}
        icon={<CoborrowerIcon />}
        useCheckbox={false}
        required={behavior === ApplicationFormPageBehavior.Required}
        onRemove={behavior === ApplicationFormPageBehavior.Optional ? handleRemoveCoborrower : undefined}
        cardClassName={styles.card}
      />
    );
  };

  const handleAddCoborrower = () => {
    if (!coborrowerTypes) {
      return;
    }

    onSetCoborrowerTypes([...coborrowerTypes, borrowerTypeToSet]);
  };

  return (
    <StepWrapper
      title={t('questionPages.addCoApplicantQuestion')}
      subtitle={t('questionPages.addCoApplicantPrompt')}
      {...restProps}
    >
      {coborrowerTypes?.map(renderCoborrower)}
      {shouldRenderAddCoborrower && (
        <OptionCard
          text={t('questionPages.addCoApplicant')}
          icon={<PlusIcon />}
          useCheckbox={false}
          onClick={handleAddCoborrower}
        />
      )}
    </StepWrapper>
  );
};

export default CoborrowersStep;
