import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import VerifyCodeStep from 'components/BorrowerProfile/LoginDetails/BasePopupSteps/VerifyCodeStep';
import { ButtonType } from 'product_modules/components/Button/Button';

interface IVerifyPhoneStepProps {
  onResendCode: () => Promise<void>;
  onSubmit: (code: string) => Promise<void>;
  message: string;
  isLoading?: boolean;
  buttonKind?: ButtonType;
}

const VerifyPhoneStep: FC<IVerifyPhoneStepProps> = ({ onResendCode, onSubmit, buttonKind, message, isLoading }) => {
  const { t } = useTranslation();
  
  return (
    <VerifyCodeStep
      buttonText={t('common.verifyPhone')}
      message={message}
      onResendCode={onResendCode}
      onSubmit={onSubmit}
      isLoading={isLoading}
      buttonKind={buttonKind}
    />
  );
};

export default VerifyPhoneStep;
