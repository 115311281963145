import { FC } from 'react';
import InfoSection from 'components/common/InfoSection';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import ContainerStyled from 'components/BrandingHelpers/ContainerStyled';
import ContainerStyledAccentColor from 'components/BrandingHelpers/ContainerStyledAccentColor';
import styles from 'components/ApplicationDetails/ApplicationOverview/ApplicationOverview.module.scss';

const ITEMS = 4;

const SkeletonApplicationOverview: FC = () => {
  const skeletonCellsMap = Array.from({ length: ITEMS }).map((_, index) => {
    return {
      id: `item-${index}`,
      name: <SkeletonText className={styles.skeletonField} width="100px" height="12px" color="primary20" lineHeight="16px" />,
      value: <SkeletonText className={styles.skeletonValue} width="140px" height="14px" color="primary20" lineHeight="20px" />,
    };
  });

  return (
    <ContainerStyled className={styles.overviewContainer}>
      <div className={styles.overviewTitle}>
        <SkeletonText className={styles.skeletonField} width="220px" height="16px" color="primary20" lineHeight="24px" />
      </div>
      <div className={styles.infoSectionDesktop}>
        <InfoSection
          sectionContent={skeletonCellsMap}
          containerClassName={styles.overviewContent}
          fieldTitleClassName={styles.fieldTitle}
          fieldValueClassName={styles.fieldValue}
          fieldClassName={styles.field}
          rows={2}
        />
      </div>
      <div className={styles.infoSectionMobile}>
        <InfoSection
          sectionContent={skeletonCellsMap}
          containerClassName={styles.overviewContent}
          fieldTitleClassName={styles.fieldTitle}
          fieldValueClassName={styles.fieldValue}
          fieldClassName={styles.field}
        />
      </div>
      <div>
        <ContainerStyledAccentColor className={styles.viewDetailsButtonSkeleton} />
      </div>
    </ContainerStyled>
  );
};

export default SkeletonApplicationOverview;
