import { useCallback, useEffect, useMemo, useState } from 'react';
import { IProduct } from 'api/digifi/ProductsApi';
import { isCoBorrowerApplicationFormPage } from 'components/ApplicationForm/CreateApplication/utils';
import CreateApplicationStep from 'enums/CreateApplicationStep';
import { VariableValue } from 'product_modules/api/Types';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import useCreateApplicationStepsNavigation from './useCreateApplicationStepsNavigation';
import useCurrentBorrower from './useCurrentBorrower';
import useSessionStorage from './useSessionStorage';

export interface ICreateApplicationData {
  borrowerType: BorrowerType;
  borrowerFormData: Record<string, VariableValue>;
  coBorrowerTypes: Array<BorrowerType>;
  coBorrowerFormData: Array<Record<string, VariableValue>>;
}

const CREATE_APPLICATION_DATA_STORAGE_KEY = 'createApplicationData';

const useCreateApplicationFlow = (product: IProduct) => {
  const borrower = useCurrentBorrower();
  const sessionStorage = useSessionStorage();
  const { clearSteps, onChangeStep, ...stepsNavigation } = useCreateApplicationStepsNavigation();

  const clearOnboardingData = useCallback(() => {
    sessionStorage.remove(CREATE_APPLICATION_DATA_STORAGE_KEY);
    clearSteps();
  }, [clearSteps]);

  const defaultBorrowerType = borrower.type;

  const availableCoBorrowerPages = useMemo(() => {
    return Object.keys(product.applicationFormPages).filter((page) => isCoBorrowerApplicationFormPage(page));
  }, [product.applicationFormPages]);

  const sessionStorageData = useMemo(() => {
    return sessionStorage.get(CREATE_APPLICATION_DATA_STORAGE_KEY, true);
  }, []);

  const initialCreateApplicationData = useMemo(() => {
    return {
      borrowerType: borrower.type,
      coBorrowerTypes: new Array(availableCoBorrowerPages.length).fill(defaultBorrowerType),
      coBorrowerFormData: [],
      borrowerFormData: {},
    };
  }, []);

  const [createApplicationData, setCreateApplicationData] = useState<ICreateApplicationData>(
    sessionStorageData || initialCreateApplicationData,
  );

  useEffect(() => {
    onChangeStep(CreateApplicationStep.Borrower, false);
  }, []);

  useEffect(() => {
    setCreateApplicationData({
      ...createApplicationData,
      borrowerFormData: { ...borrower.variables, ...createApplicationData.borrowerFormData },
    });
  }, [borrower]);

  useEffect(() => {
    sessionStorage.set(CREATE_APPLICATION_DATA_STORAGE_KEY, createApplicationData);
  }, [createApplicationData]);

  return [createApplicationData, setCreateApplicationData, clearOnboardingData, stepsNavigation] as [
    typeof createApplicationData,
    typeof setCreateApplicationData,
    typeof clearOnboardingData,
    typeof stepsNavigation,
  ];
};

export default useCreateApplicationFlow;
