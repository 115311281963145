import { FC, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import TextInput from 'components/digifi-wrappers/TextInput';
import TopRightInputButton from 'components/digifi-wrappers/TopRightInputButton';
import { removeNonDigits } from 'utils/stringUtils';
import useTimer from 'product_modules/hooks/useTimer';
import TimerMessage from 'components/digifi-wrappers/TimerMessage';
import SubmitButton from 'components/common/SubmitButton';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';

export interface IVerificationFormContextType {
  code: string;
}

const MAX_LENGTH = 6;

interface IVerificationFormProps {
  buttonTitle: string;
  resendCode: () => void;
  isLoading?: boolean;
}

const VerificationForm: FC<IVerificationFormProps> = ({ buttonTitle, resendCode, isLoading }) => {
  const { t } = useTranslation();
  const { isRunning, seconds, restart } = useTimer();
  const resendInSeconds = isRunning ? seconds : 0;
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const { values, submitForm, setFieldValue } = useFormikContext<IVerificationFormContextType>();

  const handleResendCode = () => {
    resendCode();
    restart();
  };

  const topRightElement = (
    <TopRightInputButton disabled={resendInSeconds > 0} onClick={handleResendCode}>
      {t('common.resendPhoneCode')}
    </TopRightInputButton>
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const digitsOnly = removeNonDigits(event.target.value);
    setFieldValue('code', digitsOnly);
  };

  const isButtonEnabled = values.code.length === MAX_LENGTH;

  return (
    <div>
      <TextInput
        labelTitle={t('common.authenticationCode')}
        placeholder={`${MAX_LENGTH} digits`}
        topRightElement={topRightElement}
        maxLength={MAX_LENGTH}
        disabled={isLoading}
        value={values.code}
        name="code"
        onChange={handleChange}
        required
        type="tel"
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
      />
      {isRunning && (
        <TimerMessage>
          <Trans i18nKey="common.requestAnotherCode">
            You can request another code in <strong>{{resendInSeconds}}</strong>s
          </Trans>
        </TimerMessage>
      )}
      <SubmitButton
        title={buttonTitle}
        onSubmit={submitForm}
        isButtonEnabled={isButtonEnabled}
        ref={submitButtonRef}
      />
    </div>
  );
};

export default VerificationForm;
