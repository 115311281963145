import { FC } from 'react';
import Tag from 'components/digifi-wrappers/Tag';
import getTaskTagColor from 'utils/getTaskTagColor';
import { ITask } from 'api/digifi/TasksApi';
import getTaskTagLabel from 'utils/getTaskTagLabel';
import styles from './TaskPopupTitle.module.scss';

interface ITaskPopupTitleProps {
  task: ITask;
}

const TaskPopupTitle: FC<ITaskPopupTitleProps> = ({ task }) => {
  return (
    <div className={styles.popupTitleContainer}>
      <p className={styles.title}>{task.title}</p>
      <Tag color={getTaskTagColor(task.status)}>{getTaskTagLabel(task.status)}</Tag>
    </div>
  );
};

export default TaskPopupTitle;
