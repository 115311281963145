import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/digifi-wrappers/Button';
import { EmptyDocumentImage } from 'static/images';
import styles from './ESignatureElement.module.scss';

interface IFailedToLoadDocumentProps {
  onClick: () => void;
}

const FailedToLoadDocument: FC<IFailedToLoadDocumentProps> = ({ onClick }) => {
  const { t } = useTranslation();
  
  return (
    <div className={styles.errorContainer}>
      <EmptyDocumentImage />
      <div className={styles.textContainer}>
        <p className={styles.title}>
          {t('pageBuilderElements.failedToLoadDocument')}
        </p>
        <p className={styles.errorDescription}>
          <Trans i18nKey="pageBuilderElements.errorLoadingDocument">
            An unexpected error occurred loading
            <br />
            the document.
          </Trans>
        </p>
      </div>
      <Button kind="secondary" onClick={onClick}>
        {t('pageBuilderElements.retry')}
      </Button>
    </div>
  );
};

export default FailedToLoadDocument;
