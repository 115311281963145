import React, { FC } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import LinkButton from 'components/digifi-wrappers/LinkButton';
import styles from 'components/PageLayout/FormFooterLink/FormFooterLink.module.scss';

export interface IFormFooterLinkProps {
  linkText: string;
  to: string;
  text?: string;
  className?: string;
  onLinkClick?: () => void;
}

const FormFooterLink: FC<IFormFooterLinkProps> = ({ text, linkText, to, className, onLinkClick }) => {
  const navigate = useNavigate();

  const handleLinkClick = () => {
    navigate(to);
  };

  return (
    <div className={clsx(styles.linkContainer, className)}>
      {text && (
        <p className={styles.text}>
          {text}{' '}
        </p>
      )}
      <LinkButton className={clsx(styles.link)} onClick={onLinkClick || handleLinkClick}>
        {linkText}
      </LinkButton>
    </div>
  );
};

export default FormFooterLink;
