import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import SignInForm from 'components/Auth/forms/SignInForm';
import { ISignInFormContextType } from 'components/Auth/forms/SignInForm/SignInForm';
import { AppRoute } from 'enums/AppRoute';
import {
  getCurrentAccount,
  signIn,
} from 'handlers/authSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppDispatch } from 'hooks/reduxHooks';
import { createNotification } from 'handlers/notificationsSlice';
import FormFooterLink from 'components/PageLayout/FormFooterLink';
import AuthFormContainer from 'components/Auth/AuthFormContainer';
import FormTitle from 'components/PageLayout/FormTitle';
import { HttpStatusCode } from 'enums/HttpStatusCode';

const SignIn: FC = () => {
  const { t } = useTranslation();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (values: ISignInFormContextType) => {
    try {
      setIsLoading(true);
      await dispatchWithUnwrap(signIn(values));
      await dispatchWithUnwrap(getCurrentAccount());
    } catch (error) {
      if (error.responseStatus === HttpStatusCode.NotFound) {
        createNotification({ notification: t('toastMessages.accountNotFound'), type: 'error', dispatch});

        return;
      }
      
      createNotification({ notification: t('toastMessages.incorrectEmailOrPassword'), type: 'error', dispatch });
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <UnauthorizedLayout
        hideSignInButton
      >
        <AuthFormContainer>
          <FormTitle title={t('signIn.welcomeBack')} subTitle={t('signIn.signInPrompt')} noMargin />
          <SignInForm
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
          <FormFooterLink
            text={t('signIn.newUserPrompt')}
            linkText={t('signIn.newUserLink')}
            to={AppRoute.Home}
          />
        </AuthFormContainer>
      </UnauthorizedLayout>
  );
};

export default SignIn;
