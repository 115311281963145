import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { BrandingLanguage } from 'api/digifi/SettingsApi';

const DEFAULT_NAMESPACE = 'translation';

export default class TranslationService {
  public static initialize() {
    i18next.use(initReactI18next).init({
      resources: {},
      lng: 'en',
      fallbackLng: 'en',
      returnNull: false,
      interpolation: {
        escapeValue: false,
      },
    });
  }
  
  public static translate(key: string, values: Partial<Record<string, string | number>> = {}) {
    return i18next.t(key, values);
  }

  public static setLanguage(language: string) {
    i18next.changeLanguage(language);
  }

  public static getCurrentLocale() {
    return i18next.language as BrandingLanguage;
  }

  public static async loadTranslationResources(locale: string) {
    const resource = await import(`../../../locales/${locale}/${DEFAULT_NAMESPACE}.json`);

    i18next.addResourceBundle(locale, DEFAULT_NAMESPACE, resource.default);
  }
}
