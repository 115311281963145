import React, { FC, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PopUp from 'components/digifi-wrappers/PopUp';
import PopUpContent from 'components/digifi-wrappers/PopUpContent';
import { createNotification } from 'handlers/notificationsSlice';
import ProvideEmailStep from 'components/BorrowerProfile/LoginDetails/ChangeEmailPopup/Steps/ProvideEmailStep';
import VerifyEmailStep from 'components/BorrowerProfile/LoginDetails/ChangeEmailPopup/Steps/VerifyEmailStep';
import ConfirmPasswordStep from 'components/BorrowerProfile/LoginDetails/BasePopupSteps/ConfirmPasswordStep';
import { setAccountData } from 'handlers/authSlice';
import TokenUtils from 'utils/TokenUtils';
import styles from './ChangeEmailPopup.module.scss';

enum ChangeEmailPopupSteps {
  ConfirmPassword = 'ConfirmPasswordStep',
  ProvideEmail = 'ProvideEmailStep',
  VerifyEmail = 'VerifyEmailStep',
}

interface IChangeEmailPopupProps {
  onConfirmPassword: (password: string) => Promise<void>;
  onSendCode: (email: string) => Promise<void>;
  onVerifyCode: (code: string) => Promise<void>;
  onClose: () => void;
}

const ChangeEmailPopup: FC<IChangeEmailPopupProps> = ({ onConfirmPassword, onSendCode, onVerifyCode, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(
    TokenUtils.isPasswordValidationTokenValid()
      ? ChangeEmailPopupSteps.ProvideEmail
      : ChangeEmailPopupSteps.ConfirmPassword,
  );
  const [email, setEmail] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirmPassword = async (password: string) => {
    try {
      setIsLoading(true);
      await onConfirmPassword(password);
      setStep(ChangeEmailPopupSteps.ProvideEmail);
    } catch (error) {
      createNotification({
        notification: t('toastMessages.passwordIsInvalid'),
        type: 'error',
        dispatch,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendUpdateEmailCode = async (newEmail: string) => {
    try {
      setIsLoading(true);
      await onSendCode(newEmail);

      batch(() => {
        setEmail(newEmail);
        setStep(ChangeEmailPopupSteps.VerifyEmail);
      });
    } catch (error) {
      createNotification({
        notification: error.message,
        type: 'error',
        dispatch,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateEmail = async (code: string) => {
    try {
      setIsLoading(true);
      await onVerifyCode(code);

      createNotification({
        notification: t('toastMessages.emailUpdated', { target: email }),
        type: 'success',
        dispatch,
      });

      dispatch(setAccountData({ email: email || '' }));
      onClose();
    } catch {
      createNotification({
        notification: t('toastMessages.verificationCodeInvalid'),
        type: 'error',
        dispatch,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnResendCode = async () => {
    if (!email) {
      return;
    }

    await onSendCode(email);
    createNotification({
      notification: t('toastMessages.newCodeSent', { target: email }),
      type: 'success',
      dispatch,
    });
  };

  return (
    <PopUp
      title={t('loginDetails.emailSetupVerification')}
      closePopUp={onClose}
      classNames={{
        sectionClassName: styles.popUpContainer,
        header: styles.popUpHeader,
      }}
    >
      <PopUpContent className={styles.popUpContent}>
        {step === ChangeEmailPopupSteps.ConfirmPassword && (
          <ConfirmPasswordStep
            onContinue={handleConfirmPassword}
            isLoading={isLoading}
          />
        )}
        {step === ChangeEmailPopupSteps.ProvideEmail && (
          <ProvideEmailStep
            onContinue={handleSendUpdateEmailCode}
            initialEmail={email || ''}
            isLoading={isLoading}
          />
        )}
        {step === ChangeEmailPopupSteps.VerifyEmail && (
          <VerifyEmailStep
            email={email!}
            onResendCode={handleOnResendCode}
            onSubmit={handleUpdateEmail}
            isLoading={isLoading}
          />
        )}
      </PopUpContent>
    </PopUp>
  );
};

export default ChangeEmailPopup;
