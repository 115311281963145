import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import AcceptInvite from 'components/Auth/AcceptInvite';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getInviteInfo } from 'handlers/authSlice';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import { AppRoute } from 'enums/AppRoute';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { createNotification } from 'handlers/notificationsSlice';
import FormTitle from 'components/PageLayout/FormTitle';
import AuthFormContainer from 'components/Auth/AuthFormContainer';
import OnlineSignUp from 'pages/auth/OnlineSignUp';

const CreateAccount: FC = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const { inviteData } = useAppSelector(state => state.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchData = async (inviteToken: string) => {
    try {
      setIsLoading(true);
      await dispatchWithUnwrap(getInviteInfo(inviteToken));
      setIsLoading(false);
    } catch (error) {
      createNotification({
        type: 'error',
        notification: error.message,
        dispatch,
      });

      setIsLoading(false);
      navigate(AppRoute.SignIn);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData(token);
    }
  }, [token]);

  const renderForm = () => {
    return token ? <AcceptInvite token={token} /> : <OnlineSignUp />;
  };

  if (token && (!inviteData || isLoading)) {
    return <LoaderOverlay />;
  }

  return  (
      <UnauthorizedLayout>
        <AuthFormContainer>
          <FormTitle title={t('createAccount.createYourAccount')} subTitle={t('createAccount.createAccountPrompt')} noMargin />
          {renderForm()}
        </AuthFormContainer>
      </UnauthorizedLayout>
    );
};

export default CreateAccount;
