import { useMemo } from 'react';
import { omit} from 'lodash';
import { ISystemVariableValue, TableValue, VariableValue } from 'product_modules/api/Types';
import useVariablesBySystemNames from 'product_modules/loaders/Variables/hooks/useVariablesBySystemNames';
import { systemVariablesConfig } from 'config/system-variables-config';
import getTemplatePlaceholders from 'utils/getTemplatePlaceholders';
import formatVariableValue from 'utils/formatVariableValue';
import { getCategorizedInputNames } from 'utils/getCategorizedInputNames';
import { formatSystemVariableValue } from 'utils/formatting-utils';

const TABLE_SYSTEM_COLUMN = '_id';

const formatCustomVariableValue = (value: VariableValue): unknown => {
  if (Array.isArray(value)) {
    return (value as TableValue).map((item) => omit(item, TABLE_SYSTEM_COLUMN));
  }

  return value;
};

const useTemplateRenderingContext = (template: string, context: Record<string, VariableValue | ISystemVariableValue>) => {
  const { customInputNames } = useMemo(() => {
    const placeholders = getTemplatePlaceholders(template);

    return getCategorizedInputNames(placeholders);
  }, [template]);

  const variablesPool = useVariablesBySystemNames(customInputNames);

  return useMemo(() => {
    if (!variablesPool) {
      return null;
    }

    return Object.keys(context).reduce((result, inputName) => {
      const systemVariable = systemVariablesConfig[inputName];

      if (systemVariable) {
        result[inputName] = formatSystemVariableValue(context[inputName] as ISystemVariableValue, systemVariable);

        return result;
      }

      const customVariable = variablesPool[inputName];

      result[inputName] = formatCustomVariableValue(
        formatVariableValue(customVariable, context[inputName] as VariableValue),
      );

      return result;
    }, {} as Record<string, unknown>);
  }, [context, variablesPool]);
};

export default useTemplateRenderingContext;
