import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import BrandingWrapper from 'components/PageLayout/BrandingWrapper';

const UnauthorizedRouterLayout: FC = () => {
  return (
    <>
      <BrandingWrapper>
        <Outlet />
      </BrandingWrapper>
    </>
  );
};

export default UnauthorizedRouterLayout;
