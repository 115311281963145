import React, { FC } from 'react';
import { StylesProvider } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ButtonWithImage from 'components/digifi-wrappers/ButtonWithImage';
import styles from './FilePreviewHeader.module.scss';

interface IFilePreviewHeaderProps {
  fileName: string;
  isDownloadDisabled: boolean;
  isLoading: boolean;
  onGoBack: () => void;
  onDownload: () => void;
}

const FilePreviewHeader: FC<IFilePreviewHeaderProps> = ({
  fileName,
  isDownloadDisabled,
  isLoading,
  onGoBack,
  onDownload,
}) => {
  const { t } = useTranslation();
  
  return (
    <>
      <div className={styles.header}>
        <ButtonWithImage title={t('common.back')} kind="back" onClick={onGoBack} />
        <div className={styles.fileNameContainer}>
          <h6 className={styles.fileName}>{fileName}</h6>
        </div>
        <ButtonWithImage
          title={t('tooltips.downloadDocument')}
          kind="download"
          onClick={onDownload}
          disabled={isDownloadDisabled}
          className={styles.downloadButton}
        />
      </div>
      {isLoading && (
        <StylesProvider injectFirst>
          <LinearProgress className={styles.progressBar} />
        </StylesProvider>
      )}
    </>
  );
};

export default FilePreviewHeader;
