import { FC, useEffect, useState } from 'react';
import { getBranding } from 'handlers/settingsSlice';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import TranslationService from 'product_modules/services/TranslationService';

const BrandingWrapper: FC = ({ children }) => {
  const [isSettingsLoading, setIsSettingsLoading] = useState<boolean>(true);

  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const fetchSettings = async () => {
    try {
      setIsSettingsLoading(true);

      const branding = await dispatchWithUnwrap(getBranding());

      TranslationService.setLanguage(branding.language);
      await TranslationService.loadTranslationResources(branding.language);
    } finally {
      setIsSettingsLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return <>{isSettingsLoading ? <LoaderOverlay /> : children}</>;
};

export default BrandingWrapper;
