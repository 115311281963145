import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISubmitPageParams } from 'api/digifi/PortalPageInfoApi';
import { portalPageInfoApi } from 'store/api';
import rejectWithValue from 'utils/rejectWithValue';

export const submitPortalPageInfo = createAsyncThunk(
  'portalPageInfo/submitPortalPageData',
  async (params: ISubmitPageParams, thunkApi) => {
    try {
      return await portalPageInfoApi.submitPageData(params);
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);
