import { TaskStatus } from 'api/digifi/TasksApi';
import TranslationService from 'product_modules/services/TranslationService';

const getTaskTagLabel = (status: TaskStatus): string => {
  switch (status) {
    case TaskStatus.NotDone: {
      return TranslationService.translate('otherComponents.notDone');
    }
    case TaskStatus.InProgress: {
      return TranslationService.translate('otherComponents.inProgress');
    }
    case TaskStatus.InReview: {
      return TranslationService.translate('otherComponents.inReview');
    }
    case TaskStatus.Done: {
      return TranslationService.translate('otherComponents.done');
    }
    case TaskStatus.Cancelled: {
      return TranslationService.translate('otherComponents.cancelled');
    }
    case TaskStatus.Failed: {
      return TranslationService.translate('otherComponents.failed');
    }
    default:
      return '';
  }
};

export default getTaskTagLabel;
