import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import CreateNewPasswordForm from 'components/Auth/forms/CreateNewPasswordForm';
import { ICreateNewPasswordContextType } from 'components/Auth/forms/CreateNewPasswordForm/CreateNewPasswordForm';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { createNewPassword } from 'handlers/authSlice';
import { AppRoute } from 'enums/AppRoute';
import { createNotification } from 'handlers/notificationsSlice';
import { useAppDispatch } from 'hooks/reduxHooks';
import FormTitle from 'components/PageLayout/FormTitle';
import AuthFormContainer from 'components/Auth/AuthFormContainer';

const CreateNewPassword: FC = () => {
  const { t } = useTranslation();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.pathname.split('/').reverse()[0];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: ICreateNewPasswordContextType) => {
    try {
      setIsLoading(true);
      await dispatchWithUnwrap(createNewPassword({ password: values.password, resetPasswordToken: token ||  '' }));
      setIsLoading(false);
      createNotification({
        notification: t('toastMessages.passwordUpdated'),
        type: 'success',
        dispatch,
      });

      navigate(AppRoute.SignIn);
    } catch (error) {
      setIsLoading(false);
      createNotification({
        notification: t('toastMessages.tokenInvalidOrExpired'),
        type: 'error',
        dispatch,
      });
    }
  };

  return (
    <UnauthorizedLayout
      footerLinkText={t('common.backToSignIn')}
      footerLink={AppRoute.SignIn}
    >
      <AuthFormContainer>
        <FormTitle title={t('resetPassword.createNewPassword')} />
        <CreateNewPasswordForm handleSubmit={handleSubmit} isLoading={isLoading} />
      </AuthFormContainer>
    </UnauthorizedLayout>
  );
};

export default CreateNewPassword;
