import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import WorkflowWrapper from 'components/PageLayout/WorkflowWrapper';
import RedirectHandler from 'components/RedirectHandler';

const AuthRouterLayout: FC = () => {
  return (
    <>
      <WorkflowWrapper>
        <RedirectHandler />
        <Outlet />
      </WorkflowWrapper>
    </>
  );
};

export default AuthRouterLayout;
